import React, { useEffect, useState } from 'react';
import engager1 from '../src/images/forEngager1.png';
import engager2 from '../src/images/forEngager2.png';
import engager3 from '../src/images/forEngager3.png';
import engager4 from '../src/images/forEngager4.png';





function ForEngager(){
    return(  
        <div>
       <div  style={{backgroundColor:'#db1111',borderRadius:'310px 0px 0px 0px'}}>
       <h2 class="heading" style={{fontWeight:'bold',color:'white',padding:'140px'}}>4 Steps To Acquire<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}> Talents</span></h2>
    </div>    
     {/* <p style={{textAlign:'center',color:'#eeee',padding:'10px',paddingTop:'0px'}}>
 
Engaging with Gigleji is a seamless journey towards success. Register with us to unlock a world of opportunities and talents. Post your gigs effortlessly, choose from our assessed gigtalents, and make secure payments after the work is done. Gigleji is your partner in achieving success in the gig economy!
 
</p>
         <div className="d-md-flex  m-5 " style={{justifyContent:'space-evenly',paddingBottom:"100px"}} >
       <div>
       <h3 className=" p-5 text-danger text-center animate__animated animate__zoomIn animate__delay-1s" style={{backgroundColor:'black',borderRadius:'10px'}} ><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>R</span>egister</h3>
       </div>
       <div >
     
        <h3 className="p-5 text-white text-center animate__animated animate__zoomIn animate__delay-1s" style={{backgroundColor:"#a20505",borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>P</span>ost a job</h3>
       </div>
       <div >
       
        <h3 className='p-5 text-danger text-center animate__animated animate__zoomIn animate__delay-1s' style={{backgroundColor:'black',borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>C</span>hoose talents</h3>
       </div>
       <div >
       
        <h3 className=' p-5  text-white text-center animate__animated animate__zoomIn animate__delay-1s' style={{backgroundColor:'#a20505',borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>P</span>ay safely</h3>
       </div>
      </div>  */}
         
 
          <div className="container">
              <div class="d-md-flex " >
              <div className="pt-2" >
            <h3 class="text-black pt-1">Register <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Now</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Seamless registration at Gigleji allows you to log into the platform anytime.</h6>
            <p style={{color:'#222',textAlign:'justify'}}>
            Unlocking the power of Gigleji begins with a simple registration process.
    Head to our portal, fill the required information, and you're officially
    part of the Gigleji community. Join us to explore exciting opportunities,
    connect with talented individuals, and make your mark in the gig economy.
    Don't miss out on the chance to be a part of something great!
                </p>        
    <button type="button" className="btn btn-danger btn-md">
      <a className="nav-link" href="https://app.gigleji.com/register">
        Register Now
      </a>
    </button>
           </div>
           <div>
       <img className="pt-3" src={engager1} width='100%' height="auto"></img>
           </div>
           </div>
     
 
           <div class="d-md-flex ">
           <div>
       <img src={engager2} width='100%' height="auto"></img>
           </div>
              <div className="p-2">
            <h3 class="text-black" style={{paddingTop:'20px'}}>Post a <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Job</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>You can post various gigs on the platform and wait for applications to flow in</h6>
            <p style={{color:'#222',textAlign:'justify'}}>
            Once registered, navigate to the user-friendly dashboard to post your job for free. Specify the skills you're looking for, the duration of the project, and any other essential details. The more precise, the better! This ensures that Gigleji can match you with the most suitable candidates.
                </p>        
    <button type="button" className="btn btn-danger btn-md">
      <a className="nav-link" href="https://app.gigleji.com/register">
        Start Posting
      </a>
    </button>
           </div>
     
           </div>
           <div class="d-md-flex ">
              <div>
            <h3 class="text-black" style={{paddingTop:'20px'}}>Choose <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Gigtalents</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Choose the assessed gigtalents for your gig</h6>
            <p style={{color:'#222',textAlign:'justify'}}>Our AAA (Assess, Authenticate, Aspire)
    method ensures that every profile presented to you is not just a match on
    paper but a verified, experienced professional. We go beyond the
    conventional hiring process by thoroughly assessing each candidate's
    skills, authenticating their credentials, and recognizing their
    aspirations. At Gigleji, we believe in providing you with the best
    possible options, empowering you to make informed decisions and drive
    success.
                </p>        
           </div>
              <div>
       <img src={engager3} width='100%' height="auto"></img>
           </div>
           </div>
           
           <div class="d-md-flex ">
           <div>
       <img src={engager4} width='100%' height="auto"></img>
           </div>
              <div >
            <h3 class="text-black" style={{paddingTop:'20px'}}>Pay<span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}} > Safely</span> </h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Make payments after the work is done</h6>
            <p style={{color:'#222',fontSize:'15px',textAlign:'justify'}}>
            Once you've selected the candidates who align with your project goals, the
    payment is made directly to them. We facilitate a direct connection
    between you and the Giglers, ensuring a smooth and secure transaction.
    Enjoy the convenience of hassle-free payments and focus on what matters
    most—your project's success.
                </p>        
           </div>
           </div>
       </div>
        </div>
 
    );
}
export default ForEngager;