import { Clear } from '@mui/icons-material';
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField,IconButton } from '@mui/material';
import aboutbg from '../src/images/aboutbg.png';
import InputAdornment from '@mui/material/InputAdornment';
import {useFormik} from 'formik';
import { Form } from 'react-bootstrap';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import * as Yup from "yup";
import {useNavigate} from 'react-router-dom';
import { Snackbar, SnackbarContent } from '@mui/material';
import { BASE_URL } from './Constant'; 

const Login = ({ isOpen, onClose}) => {
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [mode, setMode] = useState('login');
  const [formContent, setFormContent] = useState('login');
  const [openPassword, setOpenPassword] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [passwordType, setPasswordType] = useState("new");
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpEntered, setOtpEntered] = useState("");

  let navigate = useNavigate();
 
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email cannot be empty"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Your password must be longer than 8 characters.")
      .max(25)
      .matches(/^(?=.{8,})/, "Must Contain 8 Characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        "Must Contain One Uppercase, One Lowercase"
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        "Must Contain One Special Case Character"
      )
      .matches(/^(?=.{6,20}$)\D*\d/, "Must Contain One Number"),
      })

 
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const initialValues={
    email:'',
    password:'',
  
  }
 
  const formik =useFormik({
    initialValues,
    validationSchema,
    onSubmit:async (values) => { 
    // e.preventDefault();

    try {
    let result= await axios.post(`${BASE_URL}/api/users/login`, {email:values.email.toLowerCase(),password:values.password});
    console.log("temp",result);
    let temp = result?.data?.result?.data;
    console.log("temp",temp);
        if (temp) {
          localStorage.setItem("user", JSON.stringify({ isAuthenticated: true, userInfo: temp }));
          sessionStorage.setItem("user",JSON.stringify({ isAuthenticated: true, userInfo: temp }))
           if (temp?.type =="admin") {
             navigate('/engagementPosting');
          } else if(temp?.type == "employer"){
            navigate('/engagementPosting');
          }
          else if(temp?.type == "subEmployer"){
            navigate('/engagementPosting');
          }
        }
    
  }catch (err) {
      if (err?.response?.data?.result === 'wrong password') {
        setSnackbarMessage('Wrong password entered!');
      } else if (err?.response?.data?.result === 'User not found') {
        setSnackbarMessage('User not found');
      } else {
        setSnackbarMessage('Some error occurred during login');
      }
      setSnackbarOpen(true);
    
  }
   
  }
});


const openForgotPassword = () => {
  let email = formik.getFieldProps("email").value;

  if (email !== "") {
    axios.post(`${BASE_URL}/api/verify/send-otp`, {
      email: email
    })
    .then((result) => {
      let temp = result?.data?.result?.data;

      if (temp) {
        setUserEmail(email);
        setPasswordType("forgot");
        setMode('forgotPassword');
      } else {
        alert("User does not exist");
      }
    })
    .catch(error => {
      console.error("Error sending OTP:", error);
      // Handle the error appropriately, e.g., show a user-friendly message.
    });
  } else {
    console.log("Email is required");
  }
};


 const handleChange = (e) => {
    console.log("otp change", e.target.value);
    setOtpEntered(e.target.value);
  };



// const validateOtp = () => {
//   let email = formik.getFieldProps("email").value;
//   axios.post(`${BASE_URL}/api/verify/validate`  ,{email:email,otp:otpEntered})
//   .then((result) => {
//     let temp = result?.data?.result?.data;
//     if (temp?.type="success") {
      
//       setMode('login');
//     } else {
      
//       alert("Please enter a valid OTP");
//     }
//   })
//   .catch((err) => {
//     console.error("Error validating OTP:", err);
   
//   });

// }
const validateOtp = () => {
  let email = formik.getFieldProps("email").value;

  axios.post(`${BASE_URL}/api/verifications/validate`, { email: email, otp: otpEntered })
    .then((result) => {
      let temp = result?.data?.result?.data;
      if (temp?.type=="success") {
        // Validation successful, perform the necessary actions
        setOtpVerified(true);
        setMode('login');
      } else {
        // Invalid OTP, show an error message
        alert("Please enter a valid OTP");
      }
    })
    .catch((err) => {
      console.error("Error validating OTP:", err);
      // Handle the error appropriately, e.g., show a user-friendly message.
    });
};
  return (
    <Dialog open={isOpen} onClose={onClose} disablePortal={true} fullWidth maxWidth="sm">
        <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          message={snackbarMessage}
          style={{
            backgroundColor:
              snackbarMessage === 'Wrong password entered!' ? '#d32f2f' : '#f57c00',
          }}
        />
      </Snackbar>
      
        <div className="d-md-flex" style={{backgroundColor:'#f9f5f5'}}>
          
        <div style={{width:'100%'}}>
      
          <Clear  style={{ position: 'absolute', top: 0, right: 0 ,border:'2px solid #d4d3d3',backgroundColor:'#d4d3d3',cursor:'pointer',fontWeight:'bold'}} onClick={onClose} />

      <DialogContent> 
      {mode === 'login' && (
        <form className=" d-grid" onSubmit={formik.handleSubmit} method="post">
          <DialogTitle style={{fontWeight:"bold",color:"#403f3f"}}>Login</DialogTitle>
          <TextField
          className="mt-2"
            type="email"
            value={email}
            label="Enter Your Email"
            {...formik.getFieldProps("email")}
            fullWidth
            error={formik.touched.email && formik.errors.email ? true : false}
            helperText={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
    <TextField
      className="mt-3"
      type={showPassword ? 'text' : 'password'}
      label="Enter Password"
      value={password}
      {...formik.getFieldProps("password")}
                    fullWidth
                    error={formik.touched.password && formik.errors.password ? true : false}
                    helperText={
                      formik.touched.password && formik.errors.password
                        ? formik.errors.password
                        : null
                    }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
     />
                 {/* <Form.Group controlId="formBasicCheckbox" className="mt-2" style={{justifyContent:'right',textAlign:'right'}}>
               <a className="text-danger"  onClick={()=>{openForgotPassword()}} style={{ fontSize: '14px', textDecoration: 'none',cursor:'pointer' }}>Forgot Password?</a>
                </Form.Group> */}
            <button type="submit" className="mt-4 mb-2 btn btn-danger " >
              Login
            </button>
          <p style={{fontSize:'14px',color:'#636363'}}>
           Don't have an account?  {" "}
          <a className="text-dark"   href="https://app.gigleji.com/register" style={{cursor:'pointer',fontWeight:'bold',textDecoration:"none"}}>
           Sign Up
          </a>
        </p>
        </form>
        )}
       {mode === 'forgotPassword'  && (
    <form className="d-grid" method="post">
       <DialogTitle style={{fontWeight:"bold",color:"#403f3f"}}>Forgot Password</DialogTitle>
       <TextField
  className="mt-2"
  type="text"
  label="Enter OTP"
  value={otpEntered} 
  onChange={handleChange}  
/>
           <button type="submit" onClick = {()=> {validateOtp()}} className="mt-3 mb-1 btn btn-danger " >
              Validate OTP
            </button>
            <p className="mt-1" style={{fontSize:'14px',color:'#636363'}}>
           Didn't received code? <a className="text-dark"   style={{cursor:'pointer',fontWeight:'bold',textDecoration:"none"}}>
          Resend
          </a>
          </p>
          <p style={{fontSize:'14px',color:'#636363'}}>
           Go back to <a className="text-dark" onClick={() => setMode('login')}  style={{cursor:'pointer',fontWeight:'bold',textDecoration:"none"}}>
          login screen
          </a>
          </p>    
    </form>
  )}
  
      </DialogContent>
      </div>
      <div className="d-none d-md-block">
        <img src={aboutbg} height="400px" width="220px"></img>
      </div>
      </div>
    </Dialog>
  );
};

export default Login;
