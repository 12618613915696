import React, { useState, useEffect } from 'react';
import job from '../src/images/job1.png';
import profile from '../src/images/profile2.png';

const Count = () => {
  const [gigProfiles, setGigProfiles] = useState(0);
  const [openEngagements, setOpenEngagements] = useState(0);
  
  useEffect(() => {
    const animateCounter = (targetValue, setStateFunction) => {
      let currentValue = 0;
      const interval = setInterval(() => {
        currentValue += 1;
        setStateFunction(currentValue);

        if (currentValue === targetValue) {
          clearInterval(interval);
        }
      }, 10);
    };

    animateCounter(1000, setGigProfiles);
    animateCounter(200, setOpenEngagements);
   
  }, []);

  return (
   <div style={{backgroundColor:'#fbebe9'}}>
    <section class=" pt-2 pb-2 container d-md-flex text-center animate__animated animate__zoomIn animate__delay-2s" style={{justifyContent:'space-around'}}>
        <div>
      
        <img src={profile} alt="" height="80px" width="auto"></img>
       
        <h3 style={{fontWeight:'bold'}}>{gigProfiles}+ <p style={{fontSize:'15px',fontWeight:'none',color:'#222'}}>Profiles</p></h3>
        </div>
        <div>
        <img src={job} alt="" height="80px" width="auto"></img>
        
        <h3 style={{fontWeight:'bold'}}>{openEngagements}+ <p style={{fontSize:'15px',fontWeight:'none',color:'#222'}}>Engagements</p></h3> 
        </div>
       
      </section>
      </div>
  );
};

export default Count;
