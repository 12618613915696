import './App.css';
import axios from 'axios';
import React, { useState,useEffect,useRef} from 'react';
import point1 from '../src/images/point1.jpg';
import point2 from '../src/images/point2.jpg';
import point3 from '../src/images/point3.jpg';
import 'animate.css';
import startnow from '../src/images/startnow1.jpg';
import linkedIn from '../src/images/linkedin.png';
import mail from '../src/images/mail.png';
import call from '../src/images/Call.png';
import point4 from '../src/images/point4.jpg';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import loctn from '../src/images/locatn.png';
import 'bootstrap/dist/css/bootstrap.css';
import videogig from '../src/video/gvideo1.mp4';
import { Typewriter } from 'react-simple-typewriter';
import securePayment from '../src/images/securePayment1.png';
import quality from '../src/images/Quality1.png';
import cyber from '../src/images/easypayment.png';

import hhuman3 from '../src/images/human3.png';
import hhuman4 from '../src/images/human4.png';
import hhuman2 from '../src/images/human2.png';
import hhuman1 from '../src/images/human1.png';
import { CardActionArea, Typography } from '@mui/material';
import { useLocation,useNavigate,Routes,Route} from "react-router-dom";
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Gallery from './Gallery.js';

import Count from './Count.js';
import Login from './Login.js';
import './LoginEngager.css';

import { BASE_URL } from './Constant.js';

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const location = useLocation();
  const [backgroundColor, setBackgroundColor] = useState('#bb1d0c');
  const [index, setIndex] = useState(0);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const fadeRef = useRef();

  const openLoginPopup = () => {
    setIsLoginOpen(true);
  };

  const closeLoginPopup = () => {
    setIsLoginOpen(false);
  };

 
  const sentences = [
    
    "Unlock the world of opportunities!",
    "Your dream gigs are here!",
    "Hire the right person for your gigs!",
    "Find your dream gigs here!"
  ];
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % sentences.length);
    }, 2000);
 
    return () => clearInterval(intervalId);
  }, []);
 
 
  const renderWordsInBlack = (word, index) => (
    <span key={index} style={{ color: '#807f7e' }}>
      {word}
    </span>
  );
  
  const colorImageMap = {
    '#bb1d0c': hhuman1,
    '#db1111': hhuman4,
    '#dd3021': hhuman3,
    '#e11b0a': hhuman2,
  };

  useEffect(() => {
    const colorChangeInterval = setInterval(() => {
      const colors = ['#db1111', '#bb1d0c', '#dd3021', '#e11b0a'];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      setBackgroundColor(randomColor);
      setIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 2000);

    return () => {
      clearInterval(colorChangeInterval);
    };
  }, []);


  function togglePlayPause() {

    const video = document.getElementById('gigVedio');
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
  
    setIsPlaying(!isPlaying);
  };
  
  
  
  useEffect(() => {
    const handleScroll = () => {
      const element = fadeRef.current;
      if (element) {
        const boundingBox = element.getBoundingClientRect();
        const isInView = boundingBox.top < window.innerHeight && boundingBox.bottom >= 0;

        if (isInView) {
          element.classList.add('in-view');
        } else {
          element.classList.remove('in-view');
        }
      }
      
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);




function togglePanel(panel) {
  const panels = document.querySelectorAll('.panel');
  panels.forEach(p => {
      if (p !== panel) {
          p.classList.remove('active');
      }
  });
  panel.classList.toggle('active');
}



    const initialValues = {
      name: '',
      email : '',
      mobile : '',
      comment : ''
    };
 
    const validate = (values) => {
     let errors = {};
 
      if(!values.name){
        errors.name = "Requiured";
      }
      if(!values.email){
        errors.email = "Required";
      }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Invalid email address";
      }
      if(!values.mobile){
        errors.mobile = "Required";
      }
      if(!values.comment){
        errors.comment = "Required";
      }
return errors;
    }
const phoneRegExp = /^[0-9]*$/;
 
  const validationSchema = Yup.object({
    name: Yup.string()
    .max(25, "maximum 25 character")
    .required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    mobile: Yup.string()
    .required("phone number is required")
    .matches(phoneRegExp,"phone number is not valid")
    .min(10,"Too short")
    .max(10,"Too long"),
    comment: Yup.string()
    .min(4)
    .required("write a note"),
 
  });
 
  const formik = useFormik ({
    initialValues,
    validationSchema,
});
 
  const saveData = async () => {
    try {
      console.log('save data',formik.values);
    let response = await axios.post(`${BASE_URL}/api/contactUs/`, formik.values)
    window.alert('form submitted');
    formik.resetForm();
    } catch (error) {
      alert("please fill all the details!");
    }
   
  };
 

  return (
    <>
     <div className="App">

   <section id="home"  style={{ backgroundColor: backgroundColor,height:"auto",borderRadius:'310px 0px 0px 0px'}}>
        <div class="container-fluid d-md-flex " style={{paddingTop:'50px',paddingLeft:'60px'}} >
<div style={{width:'100%'}}>
<h1  style={{fontWeight:'bold',fontSize:'55px',color:"#ffff",paddingTop:'180px',textAlign:'center'}}>Your Next <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Gig</span> Is Here
</h1>
 
<p  style={{fontSize:'20px',fontWeight:'bold',fontStyle:'initial',color:'white',textAlign:'center'}}>
<Typewriter
  loop
  cursor
  typeSpeed={50}
  deleteSpeed={50}
  delaySpeed={1000}
  words={sentences}
  renderWords={(word, index) => renderWordsInBlack(word, index)}
/></p>
          
<p style={{textAlign:'center',color:'white'}}>Join us on this transformative journey where Gigleji redefines the landscape of gig culture, and goes beyond the ordinary. Together, let's shape the future of work.</p>
<div className="butn-align-mobl" style={{textAlign:'center'}}>
 <button
  type='button' className="btn"
  style={{marginRight:'10px',border:'1px solid #fce4ec',backgroundColor:'white',boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)'}}
><Link  class="nav-link text-dark" to="/EngagementSelection" >Get Hired</Link>
</button>
 <button type="button" className='btn' style={{border:'1px sloid #fce4ec',backgroundColor:"white",boxShadow:'0px 4px 8px rgba(0,0,0,0.5)'}} onClick={openLoginPopup}>Hire Now</button> 
<Login isOpen={isLoginOpen} onClose={closeLoginPopup}/>  
</div>
</div>
<div class=" d-md-flex pt-2" style={{width:'100%'}}>
<img className="home-bg-human"  src={colorImageMap[backgroundColor]} alt={`Human ${index + 1}`} width="100%" height="auto"/>
</div>
</div>
</section>


<Count/>



<section class=" container-fluid mt-4" id="about">
        <div class=" col-md content">
          <Link class="nav-item" to="/aboutus" style={{textDecoration:'none'}}>
        <h2 class="heading" style={{fontWeight:'bold',color:'#222'}}>Who<span class="text-danger" style={{fontfamily:'cursive',fontStyle:'italic'}}> We</span> Are</h2></Link>
        <p  style={{color:'#222',textAlign:'center',paddingLeft:'15px',paddingRight:'15px'}}>Welcome to Gigleji – a meticulously designed venture born from the collective wisdom of seasoned industrial experts with decades of experience. Our journey is defined by the invaluable mentorship of industry veterans, coupled with the vibrant energy and innovation infused by our dynamic team of young talents.
The pandemic brought in a new way of working in India across industries. As the world started embracing project-based hiring, our mentors quickly recognized the bright future of the same. 
</p>
{/* <p class="mb-4 p-4 animate__animated animate__zoomIn animate__delay-3s" style={{color:'#ffff',textTransform:'uppercase',border:'2px solid red',borderRadius:'10px 10px 10px 10px',background: "#e63939"}}>Join us on this transformative journey where Gigleji redefines the landscape of gig culture, and goes beyond the ordinary. Together, let's shape the future of work.</p> */}
</div>
</section>
<section >
    <div class="container-fluid d-lg-flex mb-5 "  style={{alignItem:"center",justifyContent:"center",paddingRight:'30px',paddingLeft:'30px'}}>
    
 <div class=" col-md card-container1">  
 <div class="card1 " style={{border:'0px',boxShadow: '2px 2px 4px rgba(100,0,0,0.4)'}}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              image={quality}
              style={{width:"100%",height:"100px",objectFit:"contain",display:"block",marginTop:"10px"}}
              alt="medal"
            />
            <CardContent>
              <Typography className="text-danger" variant="h6" style={{fontWeight:'normal',textAlign:'center'}}>Quality</Typography>
              <Typography  style={{textAlign:'center',color:'#222'}}>Onboarding quality Giglers through a rigorous screening process,ensuring the authenticity of the engagements to be posted.</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        </div>
        </div>
      <div class=" col-md card-container1 card-middle" style={{marginRight:"10px",marginLeft:"10px",marginTop:"10px",marginBottom:"10px"}}>
          <div class="card1" style={{border:'0px',boxShadow: '2px 4px 4px rgba(200,0,0,0.4)'}}>
        <Card >
          <CardActionArea>
            <CardMedia
              component="img"
              image={cyber}
              alt="medal"
              style={{width:"100%",height:"100px",objectFit:"contain",display:"block",marginTop:"10px"}}
            />
             <CardContent>
              <Typography variant="h6" className="text-danger" style={{fontWeight:'normal',textAlign:'center'}}>Security</Typography>
              <Typography style={{textAlign:'center',color:'#222'}}>24x7 customer service support.Building an engaging Gig community.Mentorship and provision for skill upgradation.Every talent will be given an equal opportunity after onboarding within a specific time frame.</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        </div>
        </div>
        <div class=" col-md card-container1" >
          <div class="card " style={{border:'0px',boxShadow: '2px 2px 4px rgba(100,0,0,0.4)'}}>
        <Card>
          <CardActionArea>
            <CardMedia
              component="img"
              image={securePayment}
              alt="medal"
              style={{width:"100%",height:"100px",objectFit:"contain",display:"block",marginTop:"10px"}}
            />
             <CardContent>
              <Typography variant="h6" className="text-danger" style={{fontWeight:'normal',textAlign:'center'}}>Easy Payment</Typography>
              <Typography  style={{textAlign:'center',color:'#222'}}>End-to-end payment process from the client organization with desired compliance facility available for the community member.</Typography>
            </CardContent>
          </CardActionArea>
        </Card>
        </div>
       </div>
        </div>
 </section>




 <section  class="container-fluid fade-in " ref={fadeRef} style={{paddingLeft:"20px",paddingTop:'20px',paddingBottom:'70px',paddingRight:'20px'}}>
        <div class="col-md joingigcontent" >
        <h2 class="heading pt-2" style={{fontWeight:'bold',color:'#222',textAlign:'center',paddingLeft:'20px'}}>Business Pro <span className="text-danger" style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Plan</span> </h2>
         <div>
         <div style={{color:'#222',textAlign:'center'}}>
  <p >As a business owner, you will always want the best talents for your company's goals. <br></br>With Gigleji, find the best talent for your project-based work. Choose from 10k+ profiles,<br></br> all assessed by industry experts.</p>
  </div>

             <div className="d-md-flex" style={{fontSize:'18px',color:'#222',listStyleType:'none',justifyContent:'space-between'}}>
              <div style={{textAlign:'center'}}>
              <img src={point1} height="100px"></img>
               <li> Get the right talents</li>
               </div>
               <div style={{textAlign:'center'}}>
                <img src={point2} height="100px"></img>
               <li>Easy onboarding </li>
               </div>
 <div style={{textAlign:'center'}}>
  <img src={point3} height="100px"></img>
 <li >Keep a track seamlessly </li>
 </div>
<div style={{textAlign:'center'}}>
<img src={point4} height="100px"></img>
  <li >Transparency guaranteed</li>
  </div>
  </div>  


            </div>
         </div>
    
</section>



 <Gallery/>
 



   <section >
    <div style={{textAlign:'center',backgroundAttachment:'fixed',paddingTop:'150px',paddingBottom:'150px',backgroundImage:`url(${startnow})`,backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
    <h2 class="heading " style={{fontWeight:'normal',color:'#eeee'}}>Unlock Freelancing<span class="text-danger" style={{fontfamily:'cursive',fontStyle:'italic'}}> Opportunities</span> By Registering</h2>
     <a class="nav-link" href="https://app.gigleji.com/register"><button type="button" className="btn btn-danger  mt-4" style={{border:'1px solid white'}}>START NOW</button></a> 
      </div>
   </section>




<div class="contactUs bg-black"  id="contact" style={{borderTop:'1px solid white'}}>
    <div class="box " >
<div class=" contact1 form" >
            <form onSubmit={formik.handleSubmit} method='post'>
             <h5 style={{color:'#222',paddingBottom:'20px',paddingTop:'10px'}}>Feel Free To Contact Us</h5>
                <input type="text"  class="form-control mx-auto" placeholder="Name*" name="name" style={{paddingTop:'12px',paddingBottom:'12px'}}
                value={formik.values.name} onChange={formik.handleChange}
                {...formik.getFieldProps("name")}
               
                />
                {formik.touched.name && formik.errors.name && (
                 <div style={{fontSize:'12px'}} className="text-danger">{formik.errors.name}</div>
                 )}
 
                <input  type="email"   class="form-control mx-auto  mt-3" placeholder="Email*" name="email"  style={{paddingTop:'12px',paddingBottom:'12px'}}
                value={formik.values.email} onChange={formik.handleChange}
                {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                   <div style={{fontSize:'12px'}} className="text-danger">{formik.errors.email}</div>
                 )}
               
               
                <input type="number"  class="form-control mx-auto  mt-3" placeholder="Mobile No*" name="mobile" style={{paddingTop:'12px',paddingBottom:'12px'}}
                value={formik.values.mobile} onChange={formik.handleChange}
                {...formik.getFieldProps("mobile")}
 
                inputProps={{ type: "number" }}
                onKeyPress = {(event) => {
                  if(!phoneRegExp.test(event.key))
                  event.preventDefault();
                }}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                   <div  style={{fontSize:'12px'}} className="text-danger">{formik.errors.mobile}</div>
                 )}
 
                <textarea  class="form-control mx-auto  mt-3 " placeholder="Enter Message*" rows="6" name="comment" 
                value={formik.values.comment} onChange={formik.handleChange}                
                />
                {formik.touched.comment && formik.errors.comment && (
                   <div className="text-danger">{formik.errors.comment}</div>
                 )}
 
                <button style={{width:'100%',paddingTop:'12px',paddingBottom:'12px'}}   type="submit" class="btn btn-dark mt-3" id="submit-button"  onClick={saveData} disabled = {!formik.isValid} >Submit</button>
              </form>
    </div>


  <div class=" contact1 info" >
  <div class=" d-sm-grid " >
            <p style={{color:'#7c7a7a'}}> <img src={loctn} width="35px" height="35px"></img><br></br> 180, Ground-floor, 8th Cross Rd, CQAL Layout,Sahakar Nagar,Bengaluru, 560092</p>
            <p style={{color:'#7c7a7a'}}> <a id="mail" href="https://mail.google.com/mail/?view=cm&fs=1&to=support@gigleji.com&su=SUBJECT&body=BODY&"></a> <img src={call} width="25px" height="25px"></img><br></br> 8310241431</p>     
            <div class="d-flex">
            <a href="mailto:support@gigleji.com" ><img src={mail} width="60px" height="45px"></img></a>
             <a  href="https://in.linkedin.com/company/gigleji" ><img src={linkedIn} width="40px" height="40px" style={{marginRight:'8px'}}></img></a>
             {/* <a href="https://www.instagram.com/peopleprocessteck/?hl=en" ><img src={insta} width="40px" height="40px"></img></a> */}
            
             </div>
            </div> 
            </div>
    <div class="contact1 map " >
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.533976726709!2d77.6591579736721!3d13.001631514221963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c1fd856203%3A0x842b83e29a9e2f6e!2sGigleji%20Teknet%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1700388970860!5m2!1sen!2sin" allowfullscreen="" loading="lazy" ></iframe>
    </div>
    </div>
    </div>
 
</div>
</>
)

}

export default App;
