import { Link } from 'react-router-dom';
import logo1 from '../src/images/logo-bg.png';
import React, { useState,useEffect} from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import * as Yup from 'yup';
import thankyou from '../src/images/thankyou.png';
import loctn from '../src/images/locatn.png';
import call from '../src/images/Call.png';
import { BASE_URL } from './Constant';
 
const ContactUs = () => {
const [formSubmitted, setFormSubmitted] = useState(false);
      const initialValues = {
        name: '',
        email : '',
        mobile : '',
        comment : '',
        purpose:'',
      };
   
  //     const validate = (values) => {
  //      let errors = {};
   
  //       if(!values.name){
  //         errors.name = "Requiured";
  //       }
  //       if(!values.email){
  //         errors.email = "Required";
  //       }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
  //         errors.email = "Invalid email address";
  //       }
  //       if(!values.mobile){
  //         errors.mobile = "Required";
  //       }
  //       if(!values.comment){
  //         errors.comment = "Required";
  //       }
  // return errors;
  //     }
  const phoneRegExp = /^[0-9]*$/;
    const validationSchema = Yup.object({
      name: Yup.string()
      .max(25, "maximum 25 character")
      .required("Name is required"),
      email: Yup.string().email().required("Email is required"),
      mobile: Yup.string()
      .required("phone number is required")
      .matches(phoneRegExp,"phone number is not valid")
      .min(10,"Too short")
      .max(10,"Too long"),
      comment: Yup.string()
      .min(4)
      .required("write a note"),
      purpose: Yup.string().required("Please select one "),
    });
   
    const formik = useFormik ({
      initialValues,
      validationSchema,
  });
   
    const saveData = async () => {
      try {
        console.log('save data',formik.values);
      let response = await axios.post(`${BASE_URL}/api/contactUs/`, formik.values);
      setFormSubmitted(true);
 
      } catch (error) {
        alert("please fill all the details!");
      }
     
    };
 
 
  return (
    <section style={{backgroundColor:'#db1111',borderRadius:'310px 0px 0px 0px'}}>
<div  style={{paddingTop:'80px'}} >
 
  {!formSubmitted ? (
    <div className=" d-md-flex p-5" style={{justifyContent:'space-evenly'}}>
    <div className="pt-4" >
            <p style={{color:'#eeee'}}> <img src={loctn}  width="25px" height="25px" style={{backgroundColor:'white',borderRadius:'50%'}}></img><br></br> 180, Ground-floor, 8th Cross Rd, CQAL Layout,<br></br>Sahakar Nagar,Bengaluru, 560092</p>
            <p style={{color:'#eeee'}}> <a id="mail" href="https://mail.google.com/mail/?view=cm&fs=1&to=support@gigleji.com&su=SUBJECT&body=BODY&"></a> <img src={call} width="25px" height="25px" style={{backgroundColor:'white',borderRadius:'50%',padding:'4px'}}></img><br></br> 8310241431</p>  
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.533976726709!2d77.6591579736721!3d13.001631514221963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11c1fd856203%3A0x842b83e29a9e2f6e!2sGigleji%20Teknet%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1700388970860!5m2!1sen!2sin" allowfullscreen="" loading="lazy" height="265px" ></iframe>
            </div>
           
    <div>
    <h2 class="heading" style={{fontWeight:'bold',color:'white',textAlign:'left'}}>Connect With<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}> Us</span></h2>
      <p style={{color:'#eeee'}}>Fill out the form below and we will contact you as soon as possible!</p>
    <form onSubmit={formik.handleSubmit} method='post'>
      <div className="row">
        <div className="col-md-6">
          <input type="text" class="form-control mt-2" placeholder="Name*" name="name" style={{paddingTop:'12px',paddingBottom:'12px'}}
            value={formik.values.name} onChange={formik.handleChange}
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name && (
            <div style={{ fontSize: '12px' }} className="text-white">{formik.errors.name}</div>
          )}
        </div>
 
        <div className="col-md-6">
          <input type="email" class="form-control mt-2" placeholder="Email*" name="email" style={{paddingTop:'12px',paddingBottom:'12px'}}
            value={formik.values.email} onChange={formik.handleChange}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email && (
            <div style={{ fontSize: '12px' }} className="text-white">{formik.errors.email}</div>
          )}
        </div>
      </div>
 
      <div className="row">
        <div className="col-md-6">
          <input type="number" class="form-control mt-3" placeholder="Mobile No*" name="mobile" style={{paddingTop:'12px',paddingBottom:'12px'}}
            value={formik.values.mobile} onChange={formik.handleChange}
            {...formik.getFieldProps("mobile")}
            inputProps={{ type: "number" }}
            onKeyPress={(event) => {
              if (!phoneRegExp.test(event.key))
                event.preventDefault();
            }}
          />
          {formik.touched.mobile && formik.errors.mobile && (
            <div style={{ fontSize: '12px' }} className="text-white">{formik.errors.mobile}</div>
          )}
        </div>
 
        <div className="col-md-6">
          <select
            class="form-select mt-3 "
            name="purpose"
            value={formik.values.purpose}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            style={{paddingTop:'12px',paddingBottom:'12px'}}
          >
            <option value="" label="Select One" />
            <option value="jobQuery" label="Job Query" />
            <option value="engagementQuery" label="Engagement Query" />
            <option value="generalQuery" label="General Query" />
          </select>
          {formik.touched.purpose && formik.errors.purpose && (
            <div style={{ fontSize: '12px' }} className="text-white">{formik.errors.purpose}</div>
          )}
        </div>
      </div>
 
      <div className="row">
        <div className="col-md-12">
          <textarea class="form-control mt-3" placeholder="Enter Message*" rows="6" name="comment"
            value={formik.values.comment} onChange={formik.handleChange}
          />
          {formik.touched.comment && formik.errors.comment && (
            <div className="text-white">{formik.errors.comment}</div>
          )}
        </div>
      </div>
 
      <button style={{ width: '100%', paddingTop: '12px', paddingBottom: '12px' }} type="submit" class="btn btn-dark mt-3 mb-3" id="submit-button" onClick={saveData} disabled={!formik.isValid}>Submit</button>
    </form>
  </div>
   </div>
  ):(
<div class="container" style={{paddingBottom:'130px',textAlign:'center',paddingTop:'20px'}}>
<img src={thankyou} alt="" height='60px' style={{marginBottom:'10px'}}></img>
<h1 style={{ color: '#2f2f2f'}}>Thank
 You!</h1>
 <p> We received your query, our team will contact you.</p>
<p>Discover amazing gig talents and engagements. You are just one click away.</p>
<a href="https://app.gigleji.com/" >Get Started</a>
 </div>
  )}
  </div>
</section>
  )
}
 
export default ContactUs;