import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Card from '@mui/material/Card';
import './Questions.css';
import cities from './Cities';
import { useFormik } from 'formik';
import Languages from './Languages';
import Payment from './Payment';
import { BASE_URL } from './Constant'; 


function Engagerquestions() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showQuestions, setShowQuestions] = useState(false);
  const[organizationName,setOrganizationName]=useState('');
  const[phoneNumber,setPhoneNumber]=useState('');
  const [industryData, setIndustryData] = useState([]);
  const [selectedIndustryDomain, setSelectedIndustryDomain] = useState('');
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [JobTitle, setJobTitle] = useState('');
  const [industryDomain, setIndustryDomain] = useState([]);
  const [industrySkills, setIndustrySkills] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedWorkSpace, setSelectedWorkSpace] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState('');
  const [EngagementDuration, setEngagementDuration] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const[engagementDescription,setEngagementDescription]=useState('');
  const[email,setEmail]=useState('');
  const [error, setError] = useState('');
  const[otherSkills,setOtherSkills]=useState('');
  const [selectedDetails, setSelectedDetails] = useState('')
  const[selectedEngagementStart,setSelectedEngagementStart]=useState('');
  const [selectedModeOfBilling, setSelectedModeOfBilling] = useState([]);
  const [descriptionGenerated, setDescriptionGenerated] = useState(false);
  const [showPaymentCard, setShowPaymentCard] = useState(false);
  const [plansData, setPlansData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [userResponses, setUserResponses] = useState({});
  const[engagerName,setEngagerName]=useState('');
  const [isValidSubscription, setIsValidSubscription] = useState(false);
  const [contractTemplate, setContractTemplate] = useState({});
  const [engagerDetails,setEngagerDetails] = useState({});
  const[selectedEngagementMode,setSelectedEngagementMode]=useState('');
  const[numberOfPositions,setNumberOfPositions]=useState('');
  const[budget,setBudget]=useState('');
  const[designation,setdesignation]=useState('');
  const [engagementModes, setEngagementModes] = useState({
    fullTime: false,
    partTime: false,
    engagementBased: false,
    serviceBased: false,
  });
  const [workspace, setWorkspace] = useState({
    onSite: false,
    offSite: false,
    both: false,     
    needBased: false,
  });



const updateUserResponses = (questionIndex, response) => {

  setUserResponses(prevResponses => ({
    ...prevResponses,
    [questionIndex]: response
  }));
  console.log("userResponses",userResponses)
}; 


  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/industry/`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setIndustryData(temp || []);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/domain`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setIndustryDomain(temp || []);
  
        if (temp && temp.length > 0 && selectedIndustry) {
          const filteredDomains = temp.filter(domain => domain.Industry === selectedIndustry);
          setIndustryDomain(filteredDomains || []);
          console.log(filteredDomains);
  
          if (filteredDomains.length > 0) {
            setIndustrySkills(filteredDomains[0].skillSet || []);
          }
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, [selectedIndustry]);




  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/plan/`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setPlansData(temp);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, []);
  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
   
  };
  
  const handleLanguagesChange = (event) => {
    setSelectedLanguages(event.target.value);
   
  };
  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedIndustryDomain(selectedDomain);
    const selectedDomainData = industryDomain.find(
      (domain) => domain.Domain === selectedDomain
    );
    if (selectedDomainData) {
      setIndustrySkills(selectedDomainData.skillSet || []);
    }
  };
  const handleSkillsChange = (event) => {
    setSelectedSkills(event.target.value);
    console.log(selectedSkills[0]);
    console.log(selectedSkills[1]);
  };
  const handleWorkSpaceChange = (event) => {
    const selectedWorkSpace = event.target.value;
    const updatedWorkSpace = {
   "onSite": selectedWorkSpace === "onSite",
      "offSite": selectedWorkSpace === "offSite",
      "both": selectedWorkSpace=== "both",
      "needBased": selectedWorkSpace === "needBased",
    };
  
    setSelectedWorkSpace(selectedWorkSpace);
    setWorkspace(updatedWorkSpace);
    console.log("workspace",updatedWorkSpace);
  };
  const handleModeOfBilling = (event) => {
    setSelectedModeOfBilling(event.target.value);
  };
  
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
    
  const handleNumberOfPositionChange = (event) => {
    const inputPositionValue = event.target.value;
      if (/^\d*$/.test(inputPositionValue)) {
        setNumberOfPositions(inputPositionValue);
        setValidationErrors('');
      } else {
        setValidationErrors('Please enter only numbers'); 
      }
      
    };
    const handleEngagementModeChange = (event) => {
      const selectedMode = event.target.value;
      const updatedEngagementModes = {
        "fullTime": selectedMode === "fullTime",
        "partTime": selectedMode === "partTime",
        "engagementBased": selectedMode === "engagementBased",
        "serviceBased": selectedMode === "serviceBased",
      };
    
      setSelectedEngagementMode(selectedMode);
      setEngagementModes(updatedEngagementModes);
     
    };
    
   
    
    const handleBudgetChange=(event)=>{
      const inputBudgetValue = event.target.value;
        if (/^\d*$/.test(inputBudgetValue)) {
          setBudget(inputBudgetValue);
          setValidationErrors('');
        } else {
          setValidationErrors('Please enter only numbers.'); 
        }
      
      };
  const handleEngagementStart = (event) => {
    setSelectedEngagementStart(event.target.value);
  };
  const handleJobTitleChange = (event) => {
    setJobTitle(event.target.value);
  };
  const handleOrganizationChange = (event) => {
    setOrganizationName(event.target.value);
  };
  const handleDesignationChange = (event) => {
    setdesignation(event.target.value);
  };
  const handleEngagerNameChange = (event) => {
    setEngagerName(event.target.value);
  };
  const handleEmailChange = (event) => {
    const inputEmail = event.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputEmail)) {
      setValidationErrors({ email: 'Please enter a valid email address' });
    } else {
      setValidationErrors({});
    }

    setEmail(inputEmail);
  };
  
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue)) {
      setEngagementDuration(inputValue);
      setError('');
    } else {
      setError('Please enter only numbers.'); 
    }
  };
  const handlePhoneNumberChange = (event) => {
    const inputPhone = event.target.value;
    if (!/^\d*$/.test(inputPhone)) {
      setValidationErrors({ phoneNumber: 'Please enter only numbers' });
    } else {
      if (inputPhone.length !== 10) {
        setValidationErrors({ phoneNumber: 'Phone number should be 10 digits' });
      } else {
        setValidationErrors({});
      }
    }

    setPhoneNumber(inputPhone);
  };
  const HoverableCard = styled(Card)({
    cursor: 'pointer',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  });
  const steps = [
    {
      question: 'What is the Organization name?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={organizationName}
          onChange={handleOrganizationChange}
          error={Boolean(validationErrors['organizationName'])}
          helperText={validationErrors['organizationName']}
        />
      ),
      validate: () => {
        if (!organizationName) {
          setValidationErrors({ organizationName: 'Please enter organization name.' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'What is the Engager name?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={engagerName}
          onChange={handleEngagerNameChange}
          error={Boolean(validationErrors['engagerName'])}
          helperText={validationErrors['engagerName']}
        />
      ),
      validate: () => {
        if (!engagerName) {
          setValidationErrors({ engagerName: 'Please enter engager name.' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'What is the Engager Designation?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={designation}
          onChange={handleDesignationChange}
          error={Boolean(validationErrors['designation'])}
          helperText={validationErrors['designation']}
        />
      ),
      validate: () => {
        if (!designation) {
          setValidationErrors({ designation: 'Please enter engager designation' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'What is the Email?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={email}
          onChange={handleEmailChange}
          error={Boolean(validationErrors['email'])}
          helperText={validationErrors['email']}
        />
      ),
      validate: () => {
        if (!email) {
          setValidationErrors({ email: 'Please enter email.' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'Enter Your Phone Number?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          maxLength="10"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          error={Boolean(validationErrors['phoneNumber'])}
          helperText={validationErrors['phoneNumber']}
        />
      ),
      validate: () => {
        if (!phoneNumber) {
          setValidationErrors({ phoneNumber: 'Please enter PhoneNumber' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'What is the job title or project name?',
      component: (
        <TextField
          id="outlined-basic"
          variant="outlined"
          fullWidth
          value={JobTitle}
          onChange={handleJobTitleChange}
          error={Boolean(validationErrors['jobTitle'])}
          helperText={validationErrors['jobTitle']}
        />
      ),
      validate: () => {
        if (!JobTitle) {
          setValidationErrors({ jobTitle: 'Please enter a job title.' });
          return false;
        }
        return true;
      },
    },
    {
      question: 'Which industry is your project related to?',
      component: (
        <Select
          label="Select Industry"
          value={selectedIndustry}
          onChange={handleIndustryChange}
          fullWidth
          error={Boolean(validationErrors['industry'])}
          >
            {industryData.map((industry) => (
              <MenuItem key={industry.Industry} value={industry.Industry}>
                {industry.Industry}
              </MenuItem>
            ))}
          </Select>
        ),
        validate: () => {
          if (!selectedIndustry) {
            setValidationErrors({ industry: 'Please select an industry.' });
            return false;
          }
          return true;
        },
      },
      {
        question: 'In which specific domain does your project fall under?',
        component: (
          <Select
            label="Select Industry Domain"
            value={selectedIndustryDomain}
            onChange={handleDomainChange}
            fullWidth
            error={Boolean(validationErrors['industryDomain'])}
          >
            {industryDomain.map((industry) => (
              <MenuItem key={industry.Domain} value={industry.Domain}>
                {industry.Domain}
              </MenuItem>
            ))}
          </Select>
        ),
        validate: () => {
          if (!selectedIndustryDomain) {
            setValidationErrors({ industryDomain: 'Please select a domain.' });
            return false;
          }
          return true;
        },
      },
         
  {
    question: 'What specific skills are required for your project?',
    component: (
      <Select
        label="Select Skills"
        value={selectedSkills}
        onChange={handleSkillsChange}
        fullWidth
        multiple
        error={Boolean(validationErrors['skills'])}
      >
        {industrySkills.map((skill, index) => (
          <MenuItem key={index} value={skill}>
            {skill}
          </MenuItem>
        ))}
      </Select>
    ),
    validate: () => {
      if (selectedSkills.length === 0) {
        setValidationErrors({ skills: 'Please select at least one skill.' });
        return false;
      }
      return true;
    },
  },
  
 
    {
      question:'Can you specify any other skills required?',
      component:(
        <TextField id="outlined-basic"  variant="outlined" value={otherSkills} fullWidth onChange={(event) => setOtherSkills(event.target.value)}
          
        error={Boolean(validationErrors['otherSkills'])}
        helperText={validationErrors['otherSkills']}
      />
    ),
    validate: () => {
     
        if (!otherSkills) {
          setValidationErrors({ otherSkills: 'Please specify any other skills required' });
          return false;
        }
        return true;
      },
  },
      
  
    {
      question:'What is the preferred work mode for this project?',
      component:(
<Select
              label="Select Work Mode"
              value={selectedWorkSpace}
              onChange={handleWorkSpaceChange}
              fullWidth
             
              error={Boolean(validationErrors['workMode'])}  
            >
              <MenuItem value="offSite">WFH</MenuItem>
              <MenuItem value="onSite">OnSite</MenuItem>
              <MenuItem value="both">Both (Hybrid)</MenuItem>
              <MenuItem value="needBased">Need based</MenuItem>
            </Select>
      ),
      validate: () => {
     
        if (!selectedWorkSpace|| selectedWorkSpace.length === 0) {
          setValidationErrors({ workMode: 'Please specify work mode' });
          return false;
        }
        return true;
      },
    },
  
    
  {
    question: 'What is the preferred language for this engagement?',
    component: (
      <Select
        label="languages"
        value={selectedLanguages}
        onChange={handleLanguagesChange}
        fullWidth
        error={Boolean(validationErrors['language'])}
      >
        {Languages.map((language, index) => (
          <MenuItem key={index} value={language}>
            {language}
          </MenuItem>
        ))}
      </Select>
    ),
    validate: () => {
      if (!selectedLanguages) {
        setValidationErrors({ language: 'Please specify Languages' });
        return false;
      }
      return true;
    },
  },
  {
        question:'What is the preferred EngagementMode for this project?',
        component:(
  <Select
                label="Select EngagementMode"
                value={selectedEngagementMode}
                onChange={handleEngagementModeChange}
                fullWidth
              
                error={Boolean(validationErrors['engagementMode'])}  
              >
                <MenuItem value="fullTime">Full Time</MenuItem>
                <MenuItem value="partTime">Part Time</MenuItem>
                <MenuItem value="engagemnetBased">Engagement Based</MenuItem>
                <MenuItem value="serviceBased">Service Based</MenuItem>
              </Select>
        ),
        validate: () => {
       
          if (!selectedEngagementMode|| selectedEngagementMode.length === 0) {
            setValidationErrors({ workMode: 'Please specify Engagement Mode' });
            return false;
          }
          return true;
        },
    },
      
 
  {
    question: 'What is the preferred mode of billing for this engagement?',
    component: (
      <Select
        label="Select Mode of billing"
        value={selectedModeOfBilling}
        onChange={handleModeOfBilling}
        fullWidth
       
        error={Boolean(validationErrors['modeOfBilling'])}
      >
        <MenuItem value="hourly">Hourly</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
        <MenuItem value="Daily">Daily</MenuItem>
        <MenuItem value="engagement based">Engagement Based</MenuItem>
      </Select>
    ),
    validate: () => {
      if (!selectedModeOfBilling || selectedModeOfBilling.length === 0) {
        setValidationErrors({ modeOfBilling: 'Please select at least one mode of billing' });
        return false;
      }
      return true;
    },
  },
  {
    question: 'When would you like the engagement to start?',
    component: (
      <Select
        label="engagement start"
        value={selectedEngagementStart}
        onChange={handleEngagementStart}
        fullWidth
        error={Boolean(validationErrors['engagementStart'])}
      >
        <MenuItem value="zeroToSevenDays">0-7 days</MenuItem>
        <MenuItem value="eightToFifteenDays">8-15 days</MenuItem>
        <MenuItem value="sixteenToThirtyDays">16-30 days</MenuItem>
        <MenuItem value="moreThan30Days">More than 30 days</MenuItem>
      </Select>
    ),
    validate: () => {
      if (!selectedEngagementStart) {
        setValidationErrors({ engagementStart: 'Please select when you would like the engagement to start' });
        return false;
      }
      return true;
    },
  },
  {
    question: 'Which city and state will be the primary location for this engagement?',
    component: (
      <Select
        label="City"
        value={selectedCity}
        onChange={handleCityChange}
        fullWidth
        error={Boolean(validationErrors['city'])}
      >
        {cities.map((city) => (
          <MenuItem key={city.id} value={city.name}>
            {city.name}, {city.state}
          </MenuItem>
        ))}
      </Select>
    ),
    validate: () => {
      if (!selectedCity) {
        setValidationErrors({ city: 'Please select a city and state' });
        return false;
      }
      return true;
    },
  },
  {
    question:'Number of Positions',
    component:(
      
      <TextField id="outlined-basic"  type="number" variant="outlined" value={numberOfPositions} fullWidth onChange={handleNumberOfPositionChange}  
      error={Boolean(validationErrors['numberOfPositions'])}
      helperText={validationErrors['numberOfPositions']}
         />
    ),
         validate: () => {
     
          if (!numberOfPositions) {
            setValidationErrors({ numberOfPositions: 'Please fill the details' });
            return false;
          }
          return true;
        },
         
    
  },
    {
      question:'Indicative Budget',
      component:(
        
        <TextField id="outlined-basic"  variant="outlined" type="number" value={budget} fullWidth onChange={handleBudgetChange}  
        error={Boolean(validationErrors['budget'])}
        helperText={validationErrors['budget']}
           />
      ),
           validate: () => {
       
            if (!budget) {
              setValidationErrors({ budget: 'Please fill the details' });
              return false;
            }
            return true;
          },
    },
        {  
     question:'Engagement Desciption',
     component:(
    <TextField
    variant="outlined"
    fullWidth
    multiline
    rows={4}
    value={engagementDescription}
    onChange={(event) => setEngagementDescription(event.target.value)}
     
  />
     ),
        },
    {
      question:'Engagement Duration(in days)',
      component:(
        
        <TextField id="outlined-basic"  type="number" variant="outlined" value={EngagementDuration} fullWidth onChange={handleInputChange}  error={Boolean(error)} 
        helperText={error}
           ></TextField>
      ),
    },  
  ];

  const handleNext = () => {
    const currentStep = steps[currentQuestionIndex];
    if (currentStep.validate && !currentStep.validate()) {
      return; 
    }
    if (currentQuestionIndex < steps.length - 1) {
      updateUserResponses(currentQuestionIndex, currentStep.component.props.value);

      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      updateUserResponses(currentQuestionIndex, steps[currentQuestionIndex].component.props.value);

      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

const handleEngagementPosting= async(template)=>{
  let user=JSON.parse(sessionStorage.getItem("user"))
  let data={
      "organizationName": organizationName,
      "phoneNumber": phoneNumber,
       "engagerName":engagerName ,
      "designation":designation,
      "email": email,
      "engagementTitle":JobTitle,
      "engagementDescription":engagementDescription,
      "location":selectedCity,
      "fullTime": engagementModes.fullTime,
  "partTime": engagementModes.partTime,
  "engagemnetBased": engagementModes.engagementBased,
  "serviceBased": engagementModes.serviceBased,
      "onSite": workspace.onSite,
      "offSite": workspace.offSite,
      "both": workspace.both,     
      "needBased": workspace.needBased,
      "availability": selectedEngagementStart,
      "engagementDuration": EngagementDuration,
      "billing": selectedModeOfBilling,
      "numberOfPositions":numberOfPositions,
      "indicativeBudget": budget,
      "degree": "",
      "industry": selectedIndustry,
      "domain": selectedIndustryDomain,
      "primarySkills": selectedSkills[0],
      "secondarySkills":selectedSkills[1],
      "otherSkills": otherSkills,
     
    //  TODO:
        "contractType": "company-payroll",
        "code" :industryDomain?.filter(
            item => item.Domain === selectedIndustryDomain && item.Industry === selectedIndustry
        )[0].code,
      
        
        
        "engagerId":JSON.parse(sessionStorage.getItem("user")).userInfo?.id,
        "contractTemplate" :template
  }
  console.log("data",data)

     sessionStorage.setItem("EngamentDetails",data)
  try {
      
          const result =  await axios.post(`${BASE_URL}/api/engagement/create`, data);
 
          if (result) {
            console.log("engagement posting data", result)
            // navigate(`/employer/engagement-posting`);
            let Unq = result?.data?.result?.data?.engagementId
            alert("Engagement Posted Sucessfully, Please continue to proceed.")
            sessionStorage.removeItem("EngamentDetails")
            // setSnack({
            //   message: `Engagement Posted successfully with Id ${Unq}`,
            //   open: true,
            //   type: 'success',
            // });
            // handleReset();
          }
          else {

          }
        } catch (error) {
          console.log("erroor", error)
          alert("Error occurred during Engagement Posting!")
          sessionStorage.removeItem("EngamentDetails")

          // setSnack({
          //   message: 'Error occurred during Engagement Posting!',
          //   open: true,
          //   type: 'error',
          // });
          console.log("ERROR LOGIN: ", { ...error });

        }
}

  const handlePaymentCard=()=>{
    if (!isValidSubscription){
      setShowPaymentCard(true);
      window.history.pushState(null, null, "/paymentmodule");
   
      }else{
      getContractTemplate()
      
    }
  }
 const getContractTemplate=async()=>{
 await axios.get(`${BASE_URL}/api/contractTemplate/find-one`).then(data => {
    let temp = data?.data?.result?.data;
    console.log("default Template", temp)
    if (temp) {
      setContractTemplate(temp);
      handleEngagementPosting(temp)

    }
  }).catch(error => {
    alert('Error occurred during Engagement Posting!')
    console.log("error", error)
  })
}
 
  const handleGenerateDescription = () => {
    // const jsonResponses = responsesToJson();
    const description = `Job Title: ${JobTitle}\nDescription: I am looking into the ${selectedIndustry} industry in ${selectedIndustryDomain} domain.\nSkills Required: ${selectedSkills},${otherSkills}\nEngagement Start:${selectedEngagementStart}days\nWork Mode: ${selectedWorkSpace}\nPreffered Language:${selectedLanguages}\nEngagement Duration:${EngagementDuration}\nPayment Mode: ${selectedModeOfBilling}`;
    setSelectedDetails(description);
    setDescriptionGenerated(true);
    console.log("industrydomain",industryDomain);
    console.log("selectedIndustryDomain",selectedIndustryDomain);
    console.log( industryDomain?.filter(  item => item.Domain === selectedIndustryDomain || item.Industry === selectedIndustry)[0].code);
    
};
const getEngagerDetails=async()=>{
  let user=JSON.parse(sessionStorage.getItem("user"));
  const apiUrl = `${BASE_URL}/api/engager/findOne/${user.userInfo?.id}`;
  console.log("app",apiUrl)

  await axios.get(apiUrl, {
    headers: {
      'Authorization':"Bearer "+user?.userInfo?.token, 
      'Accept': 'application/json',
    }
  })
  .then((response) => {
    setEngagerDetails(response?.result?.data)
    console.log('Engagement count:', response.data);
  })
  .catch((error) => {

    // Handle error
    console.error('Error fetching engagement count:', error);
  });
}
  
const handleGetEngagementCount = async () => {
  setShowQuestions(true)
  let user=JSON.parse(sessionStorage.getItem("user"))
  console.log(user.userInfo.id)
  const apiUrl = `${BASE_URL}/api/engagement/getCountByEngagerId/${user?.userInfo?.id}`;
  console.log("app",apiUrl)

  await axios.get(apiUrl, {
    headers: {
      'Authorization':"Bearer "+user?.userInfo?.token, 
      'Accept': 'application/json',
    }
  })
  .then((response) => {
    setIsValidSubscription(true)
    console.log('Engagement count:', response.data);
  })
  .catch((error) => {

    // Handle error
    console.error('Error fetching engagement count:', error);
  });
};
 

  return (
   
    <>
  {!showPaymentCard?(
  
    <div
      style={{
        position: 'relative',
        background:
        'linear-gradient(90deg, rgba(246,243,243,1) 6%, rgba(196,22,22,1) 46%, rgba(196,22,22,1) 61%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '140vh',
      }}
    >
        
          
      <div 
        style={{
          justifyContent: 'center',
          textAlign: 'center',
          alignItems: 'center',
          paddingTop: '120px',
        }}
      >
        <h3 style={{ color: '#ffff' }}>Tell us what you need to be done</h3>
        <h6 style={{ color: '#eeee' }}>
          Contact skilled gigtalents within minutes. View profiles, portfolios
          and chat with them
        </h6>
        <h6 style={{ color: '#eeee' }}>
          Pay the talents only when you are 100% satisfied with their work.
        </h6>
      </div>
      <div class="engagerqtn"
        className="container"
        style={{
         padding:'25px',
          backgroundColor: '#ffff',
         
        }}
      >
        {!showQuestions ?(
         <form > 
        <Typography style={{textAlign:'center',color:'#5d5d5f',fontSize:'20px'}}>Post an Engagement and hire talents </Typography>
        <h6 style={{fontWeight:'bold',color:'#626262',paddingTop:'10px'}}><LightbulbIcon></LightbulbIcon>Hint: It helps to be specific!</h6>
        <div style={{paddingLeft:'10px'}}><h6 style={{color:'#9e9c9c'}}>Describe what you need, For example:</h6>
           <Typography style={{color:'#9e9c9c'}}>Project Name: Enter a clear and concise title that represents your job or project, such as "Full-stack Web Developer"</Typography>
        <Typography style={{color:'#9e9c9c'}}>Industry: Select the industry that best aligns with your job or project, for instance, "Technology"</Typography>
        <Typography style={{color:'#9e9c9c'}}>Domain: Choose the specific domain within the industry, like "Web Development"</Typography>
        <Typography style={{color:'#9e9c9c'}}>Pick the skills necessary for your project, this might include "JavaScript" and also select your preffered work mode
        </Typography>
        <Typography style={{color:'#9e9c9c'}}>Review the generated description and make any necessary adjustments.</Typography>
     
          </div>
          <div style={{ textAlign: 'right', paddingRight: '10px'}}>
  <button 
    type="button"
    className="click-here-button blinking-arrow"

    onClick={()=>handleGetEngagementCount()}
  >
   Click here <ArrowCircleRightIcon style={{ fontSize: '26px', cursor: 'pointer' }}></ArrowCircleRightIcon>
  </button>
</div>
<style jsx>{`
  .click-here-button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font: inherit;
    outline: inherit;
    padding: 0;
    text-decoration: underline;
  }

  .blinking-arrow {
    animation: blink 1s infinite alternate;
    color: #0f0f0f;
  }

  @keyframes blink {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`}</style>
 </form> 
        ):(
          <div className="p-5">
            <h6 style={{color:'#4a4949'}}>{steps[currentQuestionIndex].question}</h6>
        <div>  {steps[currentQuestionIndex].component}</div>
        <>
    <div className="pt-4"  style={{ textAlign: 'right'}}>
      <button onClick={handleBack} disabled={currentQuestionIndex === 0} style={{background:'none',border:'none',fontSize:'25px'}}>
      <i class="bi bi-arrow-left-circle-fill"></i>
      </button>
      <button onClick={handleNext} disabled={currentQuestionIndex === steps.length - 1} style={{
  background: 'none',
  border: 'none',
  fontSize: '25px',
  color: currentQuestionIndex === steps.length - 1 ? '#cac8c8' : '#dd1515',
}}>
  <i className="bi bi-arrow-right-circle-fill"></i>
</button>
      </div>
      {currentQuestionIndex === steps.length - 1 && (
        <>
        <button type="button" className="btn  btn-danger" onClick={handleGenerateDescription}  style={{border:'none'}}>
          Generate description
        </button>
          {descriptionGenerated && (
                    <div className="mt-3">
                     
                     <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              value={`Note: You can edit Job description based on your requirement\n\n${selectedDetails}`}
              onChange={(event) => setSelectedDetails(event.target.value)}
            />
            <div className="pt-3" style={{textAlign:'right'}}>
            <button type='button' className="btn btn-danger"  onClick={handlePaymentCard} >Next</button></div>
                    </div>
                  )}
                  </>
      )}
      </>
    </div>
        )}    
</div>
    </div>
  ):(

   
    <div>   
<Payment/>
      </div>
  
  )}

  </>
 
  );
}

export default Engagerquestions;
