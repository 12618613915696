import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Button as BootstrapButton } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import moment from "moment";
import axios from "axios";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { BASE_URL } from './Constant';


function Giglerquestions() {

  const baseUrl = "https://app.gigleji.com"
  const initialValues = {
    name: '',
    email: '',
    phoneNumber: '',
    industry: '',
    domain: '',
    resumeFile: null,
  };
  const [industryData, setIndustryData] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('Select Industry');
  const [selectedIndustryDomain, setSelectedIndustryDomain] = useState('Select Domain');
  const [industryDomain, setIndustryDomain] = useState([]);
  const generatePasswordWithName = (name) => {
    return (
      name.charAt(0).toUpperCase() +
      name.slice(1) +
      "@" +
      moment().format("YYYY")
    );
  };


  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required!'),
    email: Yup.string().email('Invalid Email').required('Required!'),
    phone: Yup.string().max(10).min(10).required('Required!'),
    // industry: Yup.string().required('Required!'),
    // domain: Yup.string().required('Required!'),
    resumeFile: Yup.mixed().required('Required!'),
  });


  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/industry/`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setIndustryData(temp || []);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/domain`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setIndustryDomain(temp || []);

        if (temp && temp.length > 0 && selectedIndustry) {
          const filteredDomains = temp.filter(domain => domain.Industry === selectedIndustry);
          setIndustryDomain(filteredDomains || []);
          console.log(filteredDomains);

          // if (filteredDomains.length > 0) {
          //   setIndustrySkills(filteredDomains[0].skillSet || []);
          // }
        }
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, [selectedIndustry]);
  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);

  };
  const handleDomainChange = (event) => {
    const selectedDomain = event.target.value;
    setSelectedIndustryDomain(selectedDomain);
    const selectedDomainData = industryDomain.find(
      (domain) => domain.Domain === selectedDomain
    );

  };
  const handleUpload = async (values, { setSubmitting, resetForm, setFieldValue }) => {
    // Here you can implement your upload logic
    // For simplicity, let's just display console logs

    let temp1 = values.name;
    let temp2 = values.email;
    let temp3 = values.resumeFile;
    let temp4 = values.phone;
    let temp5 = selectedIndustry;
    console.log("temp5", temp5);
    let temp6 = selectedIndustryDomain;
    console.log("temp5", temp6);
    const nameArry = temp1;
    const fiName = temp1;
    const laName = temp1;
    const pass = generatePasswordWithName(fiName);
    const gigTalent = {
      firstName: fiName,
      lastName: laName,
      email: temp2,
      phoneNumber: temp4,
      industry: temp5,
      domain: temp6,
      password: pass,
      type: "gigler",
      createdBy: "GijlejiWeb",
    };

    await axios
      .post(`${baseUrl}/api/users/`, gigTalent)
      .then((result) => {
        if (result) {
          const gigId = result?.data?.result?.data?.id;
          const register = {
            userId: gigId,
            firstName: fiName,
            lastName: laName,
            email: temp2,
            phoneNumber: temp4,
            industry: temp5,
            domain: temp6,
          };
          axios
            .post(`${baseUrl}/api/userInfo/create`, register)
            .then((data) => {
              if (data) {
                const uId = data?.data?.result?.data?.userId;
                const fileData = new FormData();
                fileData.append("resume", temp3);
                fileData.append("id", uId);
                axios
                  .post(`${baseUrl}/api/userUploads/resume`, fileData)
                  .then((resumedata) => {
                    if (resumedata) {
                      alert("Resume Successfully uploaded");

                    }
                  })
                  .catch((err) => {
                    alert(err);
                  });
                setOpenDialog(true);

              }
            })
            .catch((err) => {
              console.log("data", err)
              alert(err);
            });
          setSubmitting(false);

        }
      })
      .catch((err) => {
        console.log("res",)

        alert("Email Already Exist!!, Try with different email.");
      });
    console.log('Uploading...', values);
    resetForm();

  };

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const redirectToGigleji = () => {
    window.location.href = baseUrl;
  };

  // Dropzone component
  const Dropzone = ({ setFieldValue }) => {
    const [fileName, setFileName] = React.useState('');

    const { getRootProps, getInputProps } = useDropzone({
      accept: 'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/rtf',
      onDrop: (acceptedFiles) => {
        const file = acceptedFiles[0];
        setFieldValue('resumeFile', file);
        setFileName(file.name);
      }
    });

    return (
      <div {...getRootProps()} style={{ marginBottom: '10px', padding: '20px', border: '2px dashed #aaa', borderRadius: '4px' }}>
        <input {...getInputProps()} />
        {fileName ? (
          <p>{fileName}</p>
        ) : (
          <p>Drag & drop your resume file here, or click to select file<br></br>
            <span className='text-danger'>Note: Choose a PDF file</span>
          </p>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        background: 'linear-gradient(90deg, rgba(246,243,243,1) 6%, rgba(196,22,22,1) 46%, rgba(196,22,22,1) 61%)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        minHeight: 'calc(100vh - 64px - 56px)', // Height minus navbar and footer height
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        filter: openDialog ? 'blur(15px)' : 'none',
      }}
    >
      <div
        className="container"
        style={{
          backgroundColor: '#eeee',
          padding: '20px',
          maxWidth: '400px',
          marginTop: '70px',
          marginBottom: '20px'
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleUpload}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form style={{ textAlign: 'center', color: '#000' }}>
              <h5><b>Unleash Your <span style={{ color: 'red' }}>Talents!</span></b></h5>
              <h6>Let employers discover your incredible skills in minutes. Showcase your portfolio, and start meaningful conversations.</h6>
              <h6>Get rewarded for your talents, and receive payment when your clients are thrilled with your exceptional work!</h6>
              <Field
                as={TextField}
                name="name"
                label="Name"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="name" component="div" style={{ color: 'red' }} />
              <Field
                as={TextField}
                name="phone"
                label="Phone"
                variant="outlined"
                margin="normal"
                type="number"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="phone" component="div" style={{ color: 'red' }} />
              <Field
                as={TextField}
                name="email"
                label="Email"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ marginBottom: '10px' }}
              />
              <ErrorMessage name="email" component="div" style={{ color: 'red' }} />

              {/* Replace input field with Dropzone */}

              <Select
                label="Select Industry"
                name="industry"
                value={selectedIndustry}
                onChange={handleIndustryChange}
                fullWidth

              >
                <MenuItem value="Select Industry" disabled>
                  Select Industry
                </MenuItem>
                {industryData.map((industry) => (
                  <MenuItem key={industry.Industry} value={industry.Industry}>
                    {industry.Industry}
                  </MenuItem>
                ))}
              </Select>
              {/* <ErrorMessage name="industry" component="div" style={{ color: 'red' }} /> */}


              <Select
                label="Select Industry Domain"
                name="domain"
                className="mt-2 mb-2"
                value={selectedIndustryDomain}
                onChange={handleDomainChange}
                fullWidth

              >
                <MenuItem value="Select Domain" disabled>
                  Select Domain
                </MenuItem>

                {industryDomain.map((industry) => (

                  <MenuItem key={industry.Domain} value={industry.Domain}>
                    {industry.Domain}
                  </MenuItem>
                ))}
              </Select>
              {/* <ErrorMessage name="domain" component="div" style={{ color: 'red' }} /> */}

              <Dropzone setFieldValue={setFieldValue} />
              <ErrorMessage name="resumeFile" component="div" style={{ color: 'red' }} />
              {/* Replace Material-UI Button with Bootstrap Button */}

              <BootstrapButton
                variant="contained"
                type="submit"
                disabled={isSubmitting}
                fullWidth
                style={{ marginBottom: '10px', backgroundColor: 'red', color: 'white' }}
              >
                {isSubmitting ? 'Uploading...' : 'Upload Resume'}
              </BootstrapButton>
            </Form>
          )}
        </Formik>
      </div>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thank You!</DialogTitle>
        <DialogContent>
          <p>Your resume has been uploaded successfully. You'll receive an email shortly.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
          <Button onClick={redirectToGigleji} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Giglerquestions;
