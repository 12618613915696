
    const Languages= [
        "English",
        "Hindi",
        "Telugu",
        "Tamil",
        "Kannada",
        "Malayalam",
        "German",
        "French",
        "Arabic",
      ]
export default Languages;