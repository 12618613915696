import React, { useState, useEffect } from 'react';
import auto from '../src/images/autogig.jpg';
import aviatn from '../src/images/aviatn.jpg';
import bank from '../src/images/bankgig.jpg';
import cons from '../src/images/consgig.jpg';
import Infrmtn from '../src/images/Infrmtngig.jpg';
import man from '../src/images/mangig.jpg';
 import education from '../src/images/education.png';
import textile from '../src/images/textile.png';
 import seaport from '../src/images/seaport.png';
 import civil from '../src/images/civil.png';
import './gallery.css';
import { Link } from 'react-router-dom';



const SlideShow = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
    }, 3000);
 
    return () => clearInterval(interval);
  }, [images.length, currentSlide]);
 
  useEffect(() => {
    const slidesContainer = document.querySelector('.slides');
    if (slidesContainer) {
      slidesContainer.style.transform = `translateX(-${currentSlide * (100 + 100)}px)`;
    }
  }, [currentSlide]);
  return (
    <section className="mb-5">
        {/* <div className="container-fluid">
        <img src={indus} width="20%" height="100px"></img>
        </div> */}
        <div className="container-fluid pt-1">
    <div className="slideshow-container">
      <div className="slides">
        {images.map((image, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
          >
            <img
              src={image.src}
             
              alt={image.alt}
              className="slide-image"
            />
          <p className="slide-title" >{image.title}</p>
          </div>
        ))}
      </div>
    </div>
    </div>
    {/* <div className="container-fluid" style={{ textAlign:'right' }}>
        <img src={indus1} width="20%" height="100px" ></img>
        </div> */}
    </section>
  );
        };
 
const Gallery = () => {
  const images = [
        { src: cons, alt: 'Consulting/Service Providers', title: 'Consulting/Service Providers' },
        { src: Infrmtn, alt: 'Information Technology', title: 'Information Technology' },
        { src: aviatn, alt: 'Aviation', title: 'Aviation' },
        { src: man, alt: 'Manufacturing Industries', title: 'Manufacturing Industries' },
         { src: auto, alt: 'Auto Industries', title: 'Auto Industries' },
         { src: civil, alt: 'civil', title: 'Civil Industry' },
         { src: bank, alt: 'BFSI', title: 'BFSI' },
      { src: textile, alt: 'textiles', title: 'Textile Industry' },
          { src: seaport, alt: 'seaport', title: 'Sea Port' },
       
        // Add more images/industries with suitable size if required
      //  { src: petrol, alt: 'petrol', title: 'Petroleum Industry' },
      //  { src: advertise, alt: 'advertise', title: 'Advertising' },
      // //   { src: health, alt: 'health', title: 'Health Care' },
      ];
   
 
  return (
    <div style={{backgroundColor:'#faebe9',paddingRight:'10px',paddingLeft:'10px',paddingBottom:'10px',paddingTop:'30px'}}>
 <Link className="nav-item" to="/industries" style={{ textDecoration: 'none' }}>
 <h2 class="heading" style={{fontWeight:'bold',color:'#222'}}>Focus<span class="text-danger" style={{fontfamily:'cursive',fontStyle:'italic'}}> Industries</span></h2>
</Link>
<SlideShow images={images}/>
    </div>
   
  );
};
 
export default Gallery;