import React, { useState } from 'react';
import { Box, Accordion, AccordionDetails, AccordionSummary, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './IndusDomSkill.css'; // Import a CSS file for styling
 
export default function IndusDomSkillTable({ data }) {
  const [expandedIndustry, setExpandedIndustry] = useState(null);
 
  const handleIndustryChange = (industry) => {
    setExpandedIndustry(expandedIndustry === industry ? null : industry);
  };
 
  return (
    <Box className="custom-scrollbar" sx={{ width: '100%', height: '300px', overflowY: 'auto', marginBottom: 2, borderRadius: '10px', border: '1px solid #ccc' }}>
      <TableContainer component={Paper} sx={{ borderRadius: '10px' }}>
        <Table sx={{ borderRadius: '22px' }}>
          <TableHead>
            <TableRow></TableRow>
          </TableHead>
          <TableBody>
            {data.map((industry, industryIndex) => (
              <TableRow key={industryIndex}>
                <TableCell component="th" scope="row">
                  <Accordion
                    expanded={expandedIndustry === industry.title}
                    onChange={() => handleIndustryChange(industry.title)}
                    sx={{ borderRadius: '10px', border: '1px solid #ccc' }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} >
                      <Typography>{industry.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Table>
                        <TableBody>
                          {industry.subTitles.map((domain, domainIndex) => (
                            <TableRow key={domainIndex}>
                              <TableCell style={{ borderRight: '1px solid #ccc', paddingRight: '10px' }}>{domain}</TableCell>
                              <TableCell>
                                {industry.chipDatas[domainIndex]?.map((chipData, chipIndex) => (
                                  <React.Fragment key={chipIndex}>
                                    <Chip label={chipData} sx={{ m: 1, borderRadius: '5px'  , width: "calc(100% - 100px)" }} />
                                  </React.Fragment>
                                ))}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}