
import React from 'react'


const EngagerPrivacyPolicy = () => {

  return (
    <div class="container">
       <a href="javascript:history.back()" style={{position:"fixed", top:"10px", right:"10px" , backgroundColor:"white", border:"1px solid #ccc" , color:"#333" ,padding:"10px", textDecoration:"none"}}>Back</a>
         <h3>Engager Privacy Policy</h3>
     
      <p>At, <strong>GIGLEJI TEKNET PRIVATE LIMITED</strong>, a Private Limited Company incorporated under the laws of India, having its registered office at No. 1 BC-202, 2<sup>nd</sup> Main Road, Kasturinagar, East of NGEF Layout, Bangalore, Karnataka &ndash; 560043 (hereinafter referred to as &ldquo;Gigleji&rdquo;, &ldquo;Company&rdquo;, &ldquo;We&rdquo;, &ldquo;Our&rdquo; or &ldquo;Us&rdquo;), your i.e., <strong>Engagers</strong> (defined below) privacy is of great importance to us. We understand that you entrust us with certain Personal Information (defined below) to help us provide Services through our Platform. In exchange for your trust, you expect and deserve our commitment to treat your information with respect and in accordance with the terms of this privacy policy<strong>Privacy Policy</strong>.</p>
      <p>In this Privacy Policy (&ldquo;Policy&rdquo;), we describe the information that we collect about you when you use and access <a href="http://www.gigleji.com/">http://www.gigleji.com</a> (&ldquo;Website&rdquo;) and the &ldquo;Gigleji&rdquo; technology platform (&ldquo;Application&rdquo;) accessible through desktops, mobile phones, smart phones, and tablets which includes computer software, associated media, collaterals, and online or electronic documentation  (the Website and Application hereinafter collectively referred to as the &ldquo;<strong>Gigleji Platform&rdquo; or &ldquo;Platform&rdquo;</strong>) which connects Engagers (defined below) with the <strong>Gig Talent</strong> (defined below) registered on this Platform and how we use and disclose that information.</p>
      <p>If you have any questions or comments about the Privacy Policy, please contact us at <a href="mailto:support@gigleji.com">support@gigleji.com</a>. This Policy is incorporated into and is subject to the Company&rsquo;s Terms and Conditions for Engagers, which can be accessed at <a href="http://www.gigleji.com/">http://www.gigleji.com</a> (hereinafter &ldquo;<strong>Terms</strong>&rdquo;). Your use of the Platform and Services offered thereunder and any personal information you provide us therein remains subject to the terms of the Privacy Policy and Terms.</p>
      <p>BY ACCESSING OR USING THE WEBSITE, APPLICATION AND/OR SERVICES PROVIDED THEREIN, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE BOUND BY THIS PRIVACY POLICY AND OUR TERMS. IF YOU DO NOT AGREE TO THE TERMS OR PRIVACY POLICY DO NOT USE THE SERVICES.</p>
<ol>
<strong>1.</strong><strong>YOUR PRIVACY &ndash; OUR COMMITMENT</strong>
</ol>
<p>We recognize the importance of your personal privacy. Please read the following Policy to understand how your Personal Information will be treated as you make full use of the Services offered through the Platform. Except as disclosed in this Privacy Policy or as otherwise authorized by you, we will not share your Personal Information with third parties for their marketing purposes. Your trust and confidence are our highest priority.</p>
<ol>
<strong>2.</strong><strong>CONSENT </strong>
</ol>
<p>By using our Services, you understand and accept this Privacy Policy along with any changes as may be made from time to time and thereby expressly consent to our collection, storage, processing, usage, disclosure, and transfer of Your Information in accordance with this Privacy Policy. If you are not agreeable to this, please do not use the Platform or the Services. You acknowledge that You are providing Your Information out of Your free will. All information disclosed by You shall be deemed to be disclosed willingly and without any coercion. No liability pertaining to the authenticity/genuineness/misrepresentation/fraud/negligence, etc. of the information disclosed shall lie on the Company nor will the Company in any way be fully responsible to verify any information obtained from You.</p>
<p>You have the option not to provide us the Information sought to be collected. If You do not provide us Your Information or if You withdraw the consent at any point in time, we will not be able to offer the Services available on the Platform.</p>
<p> </p>
<ol>
<strong>3. </strong><strong>DEFINITIONS </strong>
</ol>
<p>As used in these Terms and Privacy Policy, the following terms shall have the meaning set forth below:</p>
<p>(i)         <strong>&ldquo;Assessor&rdquo;</strong> means an individual who is a domain expert with great experience in their area of expertise. They assess the Gig Talent on technical and behavioural aspects and mentor the Gig Talent on a periodic basis to meet the industry expectations.</p>
<p>(ii)       <strong> </strong><strong>&ldquo;Confidential Information&rdquo; </strong>shall mean all information and materials furnished by a party which: (a) if in written format is marked as confidential, or (b) if disclosed verbally is noted as confidential at time of disclosure, or (c) in the absence of either (a) or (b) is information which a reasonable party would deem to be non-public information and confidential.  Confidential Information shall include, but not be limited to the Engager Information, and Gig Talent Information; all types of data, information, ideas, processes, procedures, software specifications, technical processes, financial information, business plans, projections, marketing data and other similar information provided by one party to the other; documentation, the existence and contents of this Agreement or agreements between the Company and the Engager whether such is transmitted in writing, orally, visually, (e.g. video terminal display) or on magnetic media, and shall include all proprietary information, customer and prospect lists, trade secrets, or proposed trade names, know-how, concepts, source code, object code, drawings, flow charts, diagrams and other intellectual property relating to the subject matter of this Agreement.</p>
<p>(iii)     <strong>&ldquo;Effective Date&rdquo;</strong> means the Date on which You accept these Terms and Privacy Policy by clicking &lsquo;Sign Up,&rsquo; &lsquo;Sign In,&rsquo; &lsquo;Log In&rsquo; or &lsquo;I Accept&rsquo; or enter into an agreement with Gigleji.</p>
<p>(iv)      <strong>&ldquo;Engagement&rdquo;</strong> means contractual obligation between the Engager and the Gig Talent for provision of services by the Gig Talent to fulfil the business goals of the Engager.</p>
<p>(v)        <strong>&ldquo;Engager&rdquo;</strong> means a Registered User who is seeking to utilize the Services provided by the Platform to hire or engage a Gig Talent</p>
<p>(vi)      <strong>&ldquo;Engager Information&rdquo; </strong>means information pertaining to the Engager&rsquo;s name, details about the organization, designation of the engager, industry and department of the engager, number of employees, location, GSTIN and PAN details, phone number, email address, sub-user information and such other information/documents that the Company may require to be submitted from time to time or as the case may be etc.</p>
<p>(vii)    <strong>&ldquo;Gig Talent&rdquo;</strong> means a Registered User who is a skilled individual seeking full time / part time / engagements by using the Services of the Platform;</p>
<p>(viii)  <strong>&ldquo;Gig Talent Information&rdquo; </strong> refers to any information, document, details mentioned, uploaded, or shared on the Gigleji Platform by the Gig Talent which includes information such as Gig Talent&rsquo;s name, gender, date of birth, email address, residential address, phone number, GSTIN and PAN details, Aadhaar Number, credit/debit card details, UPI payment details, Passport details, prerequisite skills, current working status, preferred engagement model, availability, education qualifications, work experiences, professional reference details, resume and such other information/documents that the Company may require to be submitted from time to time or as far as the case may be etc.</p>
<p>(ix)      <strong>&ldquo;Platform Content&rdquo;</strong> means all material, content and information made available on the Platform including but not limited to written text, graphics, images, logos, trademarks, audio/video material, or any other forms of expression.</p>
<p>(x)        <strong>&ldquo;Registered User&rdquo;</strong> means any person who signs up and registers by creating an Account as a Gig Talent / Engager / Assessor / Admin on the Gigleji Platform through their Google Account or LinkedIn Account or email ID for availing the Services.</p>
<p>(xi)      <strong>&ldquo;Services&rdquo;</strong> refers to the service of functioning as an online facilitator on a digital platform that seeks to connect Engagers with Gig Talent to achieve their business goals as per their industry and domain requirement, but not limited to the Services stipulated under Clause 2(B) of the Terms and such other services as may be provided by the Registered Users and the Company.</p>
<p>(i)             All other capitalized terms shall have the meaning ascribed to them in the Other Terms.</p>
<p> </p>
<ol>
<strong>4.</strong><strong>INFORMATION WE COLLECT </strong>
</ol>
<p><strong>4.1        </strong><strong>Information Provided to Us:</strong></p>
<p><strong>Personal Information: </strong></p>
<p>We receive and store Personal Information (i.e. includes information that whether on its own or in combination with other information may be used to readily identify or contact you) such as Engager Information provided by you when you register and use the Platform to create the profile. You always have the option to not provide information by choosing not to use a particular Service or feature. Importantly, we only collect Personal Information about you that we consider necessary for achieving this purpose and communicating with you about the Services being offered.</p>
<p><strong>By continuing to use our Platform you are deemed to have read the Privacy Policy and understood the purpose for which your Personal or Non-Personal Information is being collected and how the same shall be used and granting your express consent to such purpose and use. If, at a later date, you wish to withdraw this consent, please send us an email at </strong><a href="mailto:support@gigleji.com"><strong>support@gigleji.com</strong></a></p>
<p><strong>4.2        </strong><strong>Information Collected Automatically- </strong></p>
<p><strong>Non-Personal Information</strong>:</p>
<p>When you use and access the Website and/or Application, we automatically receive and record information on our server logs from your browser or mobile, which is non-personal information that does not enable the Platform to identify you personally. Non-personal information such as your hardware model, browser type, IP address, operating system version, unique device identifiers, location, your computer browser information, ISP, and other technical information, the URL or advertisement that referred the Platform, search terms you entered into a search engine that led you to the Platform, pages within the Platform that you visited, the links you clicked on the Platform, and for how long you viewed the pages on the Platform and other similar information and statistics about your interaction with the Platform. We may also collect information about how your device has interacted with our Website and/or Application, including the pages accessed and links clicked. We treat this data as Non-Personal Information, except where we are required to do otherwise under applicable law.</p>
<p><strong>4.3        </strong><strong>Data Collection Devices:</strong></p>
<p><strong>Cookies</strong>:</p>
<p>We use data collection devices such as &ldquo;cookies&rdquo; on certain pages of the Website and Application to help analyze our web page flow, measure promotional effectiveness, and promote trust and safety. &ldquo;Cookies&rdquo; are small files placed on the hard drive of your computer that assist us in providing the Services. We offer certain features that are only available through the use of a &ldquo;cookie&rdquo;. We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are &ldquo;session cookies&rdquo;, meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline our cookies if your browser permits, although in that case you may not be able to use certain features on the Website and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter &ldquo;cookies&rdquo; or other similar devices on certain pages of the Website that are placed by third parties. We do not control the use of cookies by third parties.</p>
<ol>
<strong>5.           </strong><strong>USE OF INFORMATION COLLECTED </strong>
</ol>
<p>Use of Personal Information about You is an integral part of our business. We neither rent nor sell your Personal Information to anyone. We may share your Personal Information only as described in this Privacy Policy and related documents.</p>
<p><strong>5.1        </strong><strong>Use of Personal/Non-Personal Information:</strong></p>
<p><strong>Personal Information</strong>: We will use and store your Personal Information to:</p>
<p>(i)         deliver Services to you, enable you to create an Account, register for our Services, verifying your identity and credentials, connect you with suitable Gig Talents and to otherwise enable you to use our Website, Application, and our Services;</p>
<p>(ii)       to maintain, and improve our Services, including, for example, to facilitate payments, connecting with suitable Gig Talents, sending alerts regarding engagements to suitable Gig Talents based on your needs through an automatic skill matching algorithm, an option to manually search for profiles of suitable Gig Talents on the Platform, smooth talent selection and shortlisting process, contract management with the Gig Talents and the Company, develop new features, provide customer support to You;</p>
<p>(iii)     develop safety features, authenticate all Registered Users;</p>
<p>(iv)      send updates and administrative messages;</p>
<p>(v)        market the Services and for the internal operational and administrative purposes of the Services;</p>
<p>(vi)      to process billing and payment, including sharing with secured third-party payment gateways in connection with the Engagement and/or the Platform Services;</p>
<p>(vii)    If you send us personal correspondence, such as emails or letters, or if other Registered Users or third parties send us correspondence about your activities on the Platform, we may collect such information into a file specific to you;</p>
<p>(viii)  to improve our marketing and promotional efforts;</p>
<p>(ix)      to analyze Website and/or Application usage;</p>
<p>(x)        improve the Platform content and Service offerings;</p>
<p>(xi)      customize the Platform content, layout, and Services;</p>
<p>(xii)    to provide you with a smooth, efficient, safe, and customized experience while using the Platform;</p>
<p>(xiii)  to allow you to receive quality customer care services; and</p>
<p>(xiv)   You agree that we may use your Personal Information in the file we maintain about you, and other information we obtain from your current and past activities to: resolve disputes; troubleshoot problems; measure consumer interest in the Services provided by us; inform you about new services, and updates; customize your experience; detect and protect us against error, fraud and other criminal activity; enforce our Terms; and as otherwise described to you at the time of collection.</p>
<p><strong>5.2        </strong><strong>Aggregate Information</strong></p>
<p>We will also use Personal Information, Non-Personal Information, and other information to create aggregated data for analytical and other purposes. We use this information to do internal research on your demographics, interests, site usage and behaviour on the Platform, to better understand, protect, and serve You. This information is compiled and analysed on an aggregated basis. Unless otherwise stated in this Privacy Policy, We only use this data in aggregate form.</p>
<p><strong>5.3        </strong><strong>Non-personally identifiable information</strong></p>
<p>We may freely use Non-Personally Identifiable Information in connection with the Services offered through the Website and Application, including: to administer the Services being offered thereunder, to understand how well the Service is working, to market the Services and other products and services, to develop aggregate, statistical information on usage of the Service and to generally improve the Service. Collecting this information enables us to better diagnose problems with our server and to administer our Website and/or Application. This information aids us in merchandising and in developing the design and layout of the Website and/or Application.</p>
<p><strong>Email/SMS Communications</strong>: While creating an account, you must provide your email address and telephone number, to which we will send administrative and automatic alert emails, promotional emails, and text messages regarding updates and/or new Services available on the Platform. If you wish to opt out of promotional emails and text messages, you may do so by following the &ldquo;unsubscribe&rdquo; instructions in the email, &ldquo;opt out&rdquo; instructions in the text message or by editing your account settings. All Registered Users receive administrative emails and text messages. From time to time, we may reveal general statistical information about our Platform and Services provided to you through emails and messages.</p>
<ol>
<strong>6.           </strong><strong>OUR DISCLOSURE OF YOUR INFORMATION </strong>
</ol>
<p>We may also use your Personal Information to deliver information to you that, in some cases, are targeted to your interests, alerts or mails regarding applications submitted by Gig Talents, acceptance of Engagement offer made by you to the shortlisted Gig Talent and new services available on the Platform. The following are some circumstances under which and people to whom we would share your Information with from time-to-time:</p>
<p><strong>6.1        </strong><strong>Disclosure of Personal Information: </strong></p>
<p>We do not disclose your personal information such as email address, contact details, telephone number and mailing address to any third party for marketing purposes. Except as otherwise provided under this Privacy Policy, we will disclose your Personal Information to third parties only in the following limited circumstances:</p>
<p>(i)         if you use the Service through the Website and/or Application, we may disclose your Information to Gig Talents, to respond to the application links sent to Gig Talents through the automatic email alert service, or to contact and apply for Engagements through other means available on the Platform, etc.</p>
<p>(ii)       with third parties and third party service providers, like payment gateways, payment aggregator and other payment service providers, promotion and marketing partners, consultants, research firms, business partners, for supporting Our business, to provide you Services through the Platform, for analyzing data and usage of the Service through the Website and Application, processing credit/debit card payments, operating the Service or providing support and maintenance services for the same, or providing customer service. The Company will not use your financial information for any purpose other than to complete a payment transaction with you.</p>
<p>(iii)     We and our affiliates will share/transfer some or all of the collected information (personal or otherwise) with another business entity should We (or our assets) plan to merge with, or be acquired by that business entity, or reorganization, amalgamation, restructuring of business, in connection with or during negotiation of any merger, financing, acquisition, bankruptcy, dissolution, transaction or proceeding. Should such a transaction occur, that other business entity (or the new combined entity) will be contractually bound to comply with the terms of this Privacy Policy.</p>
<p>(iv)      to any law enforcement, judicial authority, governmental or regulatory authority, to the extent required by law or legal process.</p>
<p>(v)        if in our reasonable discretion, such use or disclosure is necessary to enforce or protect our legal rights or to protect authorized third parties.</p>
<p>(vi)      when we have your consent to otherwise share the information.</p>
<p><strong>6.2</strong><strong>Non-Personally Identifiable Information:</strong> We may disclose other Non-Personally Identifiable Information to third party advertisers and advertising agencies, partners, and other parties in order for us and such third parties to analyze (a) the performance of, to operate and improve the Services offered through the Website and Application and (b) the behavior of the Registered Users. We may also disclose, use, or publish this information for promoting the Services offered under the Website and/or Application. These third parties are subject to confidentiality agreements with us and other legal restrictions that prohibit their use of the information we provide them for any other purpose except to facilitate the specific outsourced operation unless you have explicitly agreed or given your prior permission to them for additional uses.</p>
<p><strong>6.3</strong><strong>Aggregate Information:</strong> Since the aggregate information we collect does not specifically identify you, it may be shared with any number of parties. We may disclose anonymous, aggregated information to third parties such as potential business partners, advertisers, and funding sources, in order to describe our business and operations.</p>
<p><strong>6.4</strong><strong>Network Operators:</strong> Use of the Service provided through the Website and Application may involve use of the services of third-party telecommunications carriers, internet service providers and service providers (such as the services of the carrier that provides cell phone service to you). Such carriers and service providers are not our contractors, and any information that a carrier collects in connection with your use of the Service provided through the Website and/or Application is not &ldquo;Personal Information&rdquo; and is not subject to this Privacy Policy. We are not responsible for the acts or omissions of telecommunications carriers, internet service providers or service providers.</p>
<p><strong>6.5</strong><strong>Communications with Us or Through the Service:</strong> Any communication or material you transmit to us by email or otherwise, including any data other than Personal Information, questions,comments, suggestions, or feedback, etc., will be treated as, non-confidential and non-proprietary information. Except to the extent expressly covered by this Policy, anything you transmit, post, or upload may be used by us for any purpose, including but not limited to, reproduction, disclosure, transmission, publication, broadcast and posting. Furthermore, you expressly agree that we are free to use any ideas, concepts, know-how, or techniques contained in any communication you send to us without compensation and for any purpose whatsoever, including but not limited to, developing, and marketing services using such information.</p>
<p><strong> </strong></p>
<p>You should be aware that Personal Information which you voluntarily include and transmit online in a publicly accessible blog, chat room, social media platform or otherwise online, or that you share in an open forum such as in personnel panel or survey, may be viewed and used by others without any restrictions. We are unable to control such uses of your Personal Information, and by using such services you assume the risk that the Personal Information provided by you may be viewed and used by third parties for any number of purposes.</p>
<ol>
<strong>7.</strong><strong>ACCESS OR CHANGE YOUR PERSONAL INFORMATION</strong>
</ol>
<p>You may review, correct, update, change or delete your information at any time by contacting us at <a href="mailto:support@gigleji.com">support@gigleji.com</a>. To protect your privacy and security, we will verify your identity before granting access or making changes to your Personal Information. You may also change your password by directly logging into your Account through the Platform.</p>
<p>Even after you remove information from the Platform, copies of that information may remain viewable elsewhere, to the extent it has been shared with others or it was otherwise distributed pursuant to your privacy settings. Removed and deleted information may remain on backup media for up to ninety (90) days prior to being deleted from our servers or for a period as required by law.</p>
<ol>
<strong>8. </strong><strong>STORING YOUR INFORMATION</strong>
</ol>
<p>Our retention periods for personal data are based on business needs and legal requirements. We retain personal data for as long as is necessary for the processing purpose(s) for which the information was collected, or for tax or legal purposes, and any other permissible, related purpose. When personal data is no longer needed, we either irreversibly anonymize the data (and we may further retain and use the anonymized information) or securely destroy the data. Upon Termination, Gigleji shall retain the Engager Information for a period of one hundred and eighty (180) days after any cancellation or withdrawal of the registration. We may use the non-personal information provided by the Registered Users for internal analysis and archival purposes. </p>
<ol>
<strong>9.</strong><strong>GOVERNING LAW</strong>
</ol>
<p>The Agreement shall be governed by and construed in accordance with the laws of India, without giving effect to the principles of conflict of laws thereunder.</p>
<p>Any dispute or difference, whether on the interpretation or otherwise, in respect of any Terms or provisions in the Policy hereof shall be referred to an independent arbitrator to be appointed by the Company. Such an arbitrator&rsquo;s decision shall be final and binding on the parties. The arbitration shall be in accordance with the Arbitration and Conciliation Act, 1996, as amended or replaced from time to time. The seat and venue of arbitration shall be Bangalore, India and the language of the arbitration shall be English.</p>
<p>Subject to the aforesaid, the Courts at Bangalore shall have exclusive jurisdiction over any proceedings arising in respect of the Agreement.</p>
<ol>
<strong>10.        </strong><strong>AMENDMENTS TO THE PRIVACY POLICY</strong>
</ol>
<p>We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the Privacy Policy in effect at the time such information is used. If we decide to change our Privacy Policy, we will periodically inform you and post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it. Users are bound by any changes to the Privacy Policy when they continue to use the Platform after such changes have been first posted.</p>
<ol>
<strong>11.        </strong><strong>SECURITY OF PERSONAL INFORMATION </strong>
</ol>
<p>The Company does not itself store Your bank account / payment card account information and does not have direct control over or responsibility for Your bank account / payment card account information. Hence, Company cannot guarantee that transmissions of Your bank account / payment card account information or personal information will always be secure or that unauthorized third parties will never be able to defeat the security measures taken by Company or Company&rsquo;s third-party service providers. Company assumes no liability or responsibility for disclosure of Your information due to any reason, including but not limited to errors in transmission, unauthorized third-party access, or other causes beyond its control. The Company is always committed to keep such personal information safe and ensure that such data / information is only transacted over secure websites of approved payment gateways which are digitally encrypted and provide highest possible care.</p>
<p>Although we shall try our best to protect Your data, we cannot take any guarantee for the security of Your data transmitted through the Platform. We will take reasonable security measures and procedures, and as specified by applicable law, to maintain appropriate physical, technical and administrative security to help prevent loss, misuse, or unauthorized access, disclosure, or modification of Personal Information. While we take these reasonable efforts to safeguard your personal information, you acknowledge and agree that no system or transmission of data over the Internet or any other public network can be guaranteed to be 100% secure. You should take steps to protect against unauthorized access to your password, phone, and computer, among other things, by signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess, and keeping your log-in and password private. We are not responsible or liable in any manner whatsoever for your account getting hacked or for the unauthorized use of your information or for any lost, stolen, compromised passwords, or for any activity on your Account via unauthorized password activity. You agree and undertake that you shall not hold Company liable therefore in any way. The use and storage of any information, including without limitation, password, account information, transaction activity and any other information available on your internet access device or computer or mobile phone is at your own risk and responsibility. You furthermore acknowledge and accept that Company may not encrypt all content or communications shared on the Platform.</p>
<ol>
<strong>12.        </strong><strong>GRIEVANCE REDRESSAL MECHANISM </strong>
</ol>
<p>In accordance with the (i) Information Technology Act, 2000, the (ii) Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Information) Rules, 2011, and the (iii) Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021, and based on rules, regulations and amendments made there under, in case of grievances you can contact:</p>
<p>Details of the Grievance Officer</p>
<p>Name: Manoj Mahapatra</p>
<p>Designation: Chief Operating Officer</p>
<p>Phone:+919342524121</p>
<p>Email Id: <a href="mailto:Manoj.m@gigleji.com">Manoj.m@gigleji<strong>.com</strong></a></p>
<p>Time: Monday &ndash; Friday (10.00 : 18.00 hrs)</p>
<p> </p>
<p>In the event you wish to make a complaint regarding any violation of the provisions of the Privacy Policy, you may send a written complaint to the Grievance Officer, who shall acknowledge the complaint within twenty-four (24) hours and redress the complaint within fifteen (15) days.</p>
<ol>
<strong>13. </strong><strong>CONTACT DETAILS </strong>
</ol>
<p>Office:  180, Ground-floor, 8th Cross Rd, CQAL Layout, Sahakar Nagar, Bengaluru, Karnataka 560092</p>
<p>Please feel free to reach out to us at our Customer Care helpline: +91-9686389523  and/or send us an email at <a href="mailto:support@gigleji.com">support@gigleji.com</a>.</p>
<p> </p>
<p>This Privacy Policy was last updated on November 14, 2022.</p>
<p> </p>
<p><strong>Copyright &copy; GIGLEJI TEKNET PRIVATE LIMITED, 2022. All Rights Reserved</strong></p>
</div>
  )
}

export default EngagerPrivacyPolicy;