import React from 'react'
import { Link } from 'react-router-dom';
const footer = () => {
  return (
    <div>
      <div class="footer bg-black ">
    <p style={{fontSize:'small',color:'#eeee',fontWeight:'bold'}}> <i class="bi bi-c-circle" ></i> 2022 Gigleji Teknet Pvt.Ltd.</p>
  <ul style={{listStyleType: 'none', margin: 0, padding: 0}}>
    <li style={{display: 'inline-block', marginRight: '10px'}}>
      <Link to={"/termsandconditions"} style={{fontSize:'small'}}>Terms and Conditions</Link>
      <span style={{borderRight: '1px solid gray', padding: '0 10px'}}></span>
    </li>
    <li style={{display: 'inline-block', marginRight: '10px'}}>
      <Link to={"/Refundpolicy"} style={{fontSize:'small'}}>Refund and Cancellation policy</Link>
      <span style={{borderRight: '1px solid gray', padding: '0 10px'}}></span>
    </li>
    <li style={{display: 'inline-block'}}>
      <Link to={"/Privacypolicy"} style={{fontSize:'small'}}>Privacy Policy</Link>
    </li>
  </ul>
</div>
    </div>
  )
}

export default footer
