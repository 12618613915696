
import './App.css';
import teambg from '../src/images/teambg3.jpg';
import React, { useRef,useEffect} from 'react';
import mission from '../src/images/mission.png';
import vision from '../src/images/vision.png';
import team2 from '../src/images/aboutbg2.png';
import aboutbg1 from '../src/images/aboutbg1.jpg';
import anuja from '../src/images/anuja.png';
import kanyush2 from '../src/images/kanyush4.png';
import arjun from '../src/images/arjun.png';
import vinay from '../src/images/vinay.png';
import linkedin from '../src/images/linkedin.png';
import aspiring from '../src/images/aspiring.png';
import authentic from '../src/images/authentic.png';
import assesed from '../src/images/assesed.png';
import kavya1 from '../src/images/kavya1.png';
import sneha from '../src/images/sneha.png';


import './AboutUs.css';
function AboutUs(){
  const fadeRef = useRef();
  
  useEffect(() => {
    const handleScroll = () => {
      const element = fadeRef.current;
      if (element) {
        const boundingBox = element.getBoundingClientRect();
        const isInView = boundingBox.top < window.innerHeight && boundingBox.bottom >= 0;

        if (isInView) {
          element.classList.add('in-view');
        } else {
          element.classList.remove('in-view');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

return(

<section>
<div style={{backgroundColor:'#db1d0c',borderRadius:'310px 0px 0px 0px',paddingTop:'100px'}}>
<div className="d-md-flex " style={{alignItems:'center',justifyContent:'center',backgroundAttachment:'fixed'}}>

      <div style={{paddingTop:'20px',paddingLeft:'20px',paddingRight:'20px'}}>
      <h2 class="heading" style={{fontWeight:'bold',color:'white'}}>About<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}> Us</span></h2>
        <p className="  pt-1" style={{color:'#eeee',textAlign:'center'}}>
        Gigleji is more than just a platform !! it's a commitment to revolutionizing the gig culture.We aim to extend this transformation not only to popular industries but also across sectors often overlooked.Our young and visionary team envisions a future where freelancers and businesses seamlessly connect,transcending traditional boundaries.
We onboard talents after a strict assessment,ensuring the best of talents for businesses.
<p>
In essence,Gigleji is a catalyst for change in how work gets done.By bridging the gap between freelancers and businesses,we are breaking new ground in industries beyond the mainstream,fostering a collaborative ecosystem that benefits all stakeholders.
</p>
 </p>
      </div> 
      <div>
      <img  src={team2} alt="team" width="100%" height="auto" style={{paddingBottom:'60px'}}/>
      </div> 
    </div>

    <div className="d-flex"  style={{background:`url(${aboutbg1})`,padding:'26px',backgroundAttachment:'fixed',justifyContent:'space-around'}}>
       <div> 
        <img src={assesed} alt="" height="120px" width="100px"></img>
       <h3 className="animate__animated animate__zoomIn animate__delay-1s" style={{color:'#eeee'}} >Assessed</h3>
       </div>
       <div style={{paddingLeft:'15px'}}>
        <img src={aspiring} alt="" height="120px" width="100px"></img>
        <h3 className="text-danger animate__animated animate__zoomIn animate__delay-1s">Aspiring</h3>
       </div>
       <div>
        <img src={authentic} alt="" height="120px" width="100px"></img>
        <h3 className=' animate__animated animate__zoomIn animate__delay-1s' style={{color:'#eeee'}}>Authentic</h3>
       </div>
      </div>
      </div>


    <div className="container-fluid d-md-flex  mt-5 ">
<div className="p-5" style={{backgroundColor:'#2d2c2c'}}>
<h4 style={{textAlign:'center',color:'#eeee',paddingBottom:'20px'}}>Vision</h4>

        <p   style={{color:'#222',textAlign:'center',backgroundColor:'whitesmoke',paddingTop:'10px',paddingBottom:'40px',paddingRight:'40px',paddingLeft:'40px'}}><img src={vision} alt=""  height="80px" style={{paddingBottom:'5px'}}></img><br></br>  Gigleji envisions a future where the world of freelancing is accessible, efficient, and enriching for both businesses and freelancers. We aspire to be the go-to platform that transcends geographical boundaries, making it easy for businesses to discover the right talent and for freelancers to showcase their skills. Our vision is to create a global community where the gig economy thrives, fostering creativity, diversity, and economic empowerment. </p>
      </div>
      <div className="p-5" style={{backgroundColor:'#d12727'}}>
        <h4  style={{textAlign:'center',color:'#eeee',paddingBottom:'20px'}}>Mission</h4>
      <p  style={{color:'#222',textAlign:'center',backgroundColor:'whitesmoke',paddingTop:'10px',paddingBottom:'40px',paddingLeft:'40px',paddingRight:"40px"}}>  <img src={mission} alt="" height="80px" style={{paddingBottom:'5px'}}></img><br></br>
At Gigleji, our mission is to redefine the landscape of freelance work by providing a seamless platform that effortlessly connects businesses with talented freelancers. We are committed to simplifying the hiring process for businesses and empowering freelancers to find meaningful opportunities. Through innovative technology and a collaborative ecosystem, we aim to foster a dynamic marketplace where work is not just a transaction but a fulfilling, mutually beneficial experience.
      </p>
      </div>
    </div>
<div className="mt-5 p-5 d-md-grid fade-in" ref={fadeRef} style={{backgroundImage:`url(${teambg})`,backgroundAttachment:'fixed'}}>
<div>
<h2 class="heading" style={{fontWeight:'bold',color:'white'}}>Our<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}> Team</span></h2>
<p className="text-center" style={{color:'#eeee'}}>Those who drive our vision forward</p>
</div>
<div className="mt-4 d-md-flex" style={{justifyContent:'space-evenly'}}>
  <div>
  <div>
    <img src={anuja} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Anuja Guha<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>Director of Marketing</p></p>
    <a href="https://www.linkedin.com/in/anuja-guha" target="_blank"> <img src={linkedin} height="24px" width="24px" style={{cursor:'pointer'}}></img></a>
    </div>
  </div>
    <img src={kanyush2} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Kanyush Mohapatra<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>Tech Trailblazer
</p></p>
  <a href="https://www.linkedin.com/in/kanyush-mohapatra/" target="_blank"><img src={linkedin} height="24px" width="24px" style={{cursor:'pointer'}}></img></a>  
    </div>
  </div>

  <div>
  <div>
  <img src={vinay} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Vinay Sunkad<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>Code Maven</p></p>
   <a href="https://www.linkedin.com/in/vinay-s-sunkad-9a6950177/" target="_blank"><img src={linkedin} alt="" height="24px" width="24px" style={{cursor:'pointer'}}></img></a> 
    </div>
  </div>
    <img src={kavya1} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Kavya H S<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>UI Maestro</p></p>
    <a href="https://www.linkedin.com/in/kavya-h-s-35a28025a/" target="_blank"> <img src={linkedin} height="24px" width="24px" style={{cursor:'pointer'}}></img></a>
    </div>
  </div>

  <div>
  <div>
    <img src={arjun} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Arjun Ramesh<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>The Visionaire</p></p>
   <a href="https://www.linkedin.com/in/arjun-ramesh-8b6a24230/" target="_blank"> <img src={linkedin} alt="" height="24px" width="24px" style={{cursor:'pointer'}}></img></a>
    </div>
    <img src={sneha} height="250px" width="250px" style={{borderRadius:'20px',objectFit: 'cover'}}></img>
    <div className="p-2 d-flex" style={{justifyContent:'space-between'}}>
    <p style={{fontWeight:'bold'}}>Sneha S<br></br><p style={{fontWeight:'normal',fontSize:'14px'}}>Buzz Builder</p></p>
    <a href="https://www.linkedin.com/in/sneha-s-7b6abb25b/" target="_blank"> <img src={linkedin} height="24px" width="24px" style={{cursor:'pointer'}}></img></a>
    </div>
    </div>
  </div>
  </div>
</div>
</section>
    );
}
export default AboutUs;