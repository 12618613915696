import React, { useState,useEffect,useRef} from 'react';
import logo1 from '../src/images/logo-bg.png';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isTogglerClicked, setIsTogglerClicked] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const navbar = document.querySelector('.navbar');
          const navLinks = document.querySelectorAll('.nav-link');
    
          if (navbar) {
            if (window.scrollY > 100) {
              navbar.style.backgroundColor = 'white';
              navLinks.forEach((link) => {
                link.style.color = 'black';
              });
            } else {
              navbar.style.backgroundColor = 'transparent';
              navLinks.forEach((link) => {
                link.style.color = 'white';
              });
            }
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        handleScroll(); 
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
      const handleNavbarTogglerClick = () => {
        setIsTogglerClicked(!isTogglerClicked);
       
      };
  return (
    <div>
          <nav className={`navbar navbar-expand-lg  bg fixed-top ${isTogglerClicked ? 'bg-white' : ''}`} >
      <Link  class="navbar-brand" to="/home"><img  class="img-fluid " style={{marginLeft:'8px'}} src={logo1} width="120px" alt=""/></Link>
          <button class="navbar-toggler" type="button"  data-bs-toggle="collapse"  data-bs-target="#navmenu" onClick={handleNavbarTogglerClick} style={{backgroundColor:'#bb1d0c',marginRight:'10px',border:'2px solid white'}} >
            <span class="navbar-toggler-icon text-white" ></span>
          </button>
          <div class="collapse navbar-collapse bg-custom" id="navmenu">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item " >
              <Link  className={`nav-link ${isTogglerClicked ? 'text-black' : ''}`} to="/home" >HOME</Link>
              </li> 
              <li class="nav-item ">
              <Link  className={`nav-link ${isTogglerClicked ? 'text-black' : ''}`} to="/aboutus" >ABOUT</Link>
              </li>
              <li class="nav-item ">
              <Link  className={`nav-link ${isTogglerClicked ? 'text-black' : ''}`} to="/industries">INDUSTRIES</Link>
              </li>
              <li class="nav-item dropdown">
  <a className={`nav-link dropdown-toggle ${isTogglerClicked ? 'text-black' : ''}`}  role="button" data-bs-toggle="dropdown" style={{fontSize:'16px'}}>HOW WE WORK</a>
  <ul class="dropdown-menu">
    <li className="pt-1" style={{textAlign:'center'}}><Link className="text-black" style={{textDecoration:'none'}} to="/forEngager">For Engager</Link></li>
     <li className="pt-2 pb-1" style={{textAlign:'center'}}><Link className="text-black" style={{textDecoration:'none'}}  to="/forTalents">For Talents</Link></li> 
  </ul>
</li>
              <li class="nav-item ">
               <Link className={`nav-link ${isTogglerClicked ? 'text-black' : ''}`} to="/contact" >CONTACT US</Link>
              </li>  
               <li class="nav-item " >
    <a class="nav-link " href="https://app.gigleji.com/"><button type="button" style={{fontSize:'13px',border:'1px solid white'}}   class="btn btn-dark btn-sm ">LOG IN</button></a>
     </li>  
     <li>
     <a class="nav-link" href="https://app.gigleji.com/register"> <button type="button" style={{fontSize:'13px',border:'1px solid white'}}   class="btn btn-danger btn-sm">SIGN UP</button></a>
     </li> 
    </ul>
   </div>
   </nav>
    </div>
  )
}

export default Navbar;
