import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/system';
import logo1 from '../src/images/logo-bg.png';
import { MdCheck, MdClose } from 'react-icons/md';
import { FaRupeeSign } from 'react-icons/fa';
import { BASE_URL,RAZOR_PAY_ID,RAZOR_PAY_KEY } from './Constant';
function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payment = () => {
  const [plansData, setPlansData] = useState([]);
  const [darkMode, setDarkMode] = useState(false);

  async function showRazorpay(plan) {

    let user=JSON.parse(sessionStorage.getItem("user"))

    const today = new Date();

    const planValidTo = new Date();
    planValidTo.setDate(today.getDate() + 30);

// Format dates as YYYY-MM-DD (assuming you want ISO string format)
      const todayFormatted = today.toISOString().split('T')[0];
      const planValidToFormatted = planValidTo.toISOString().split('T')[0];
    try {
      let data={
        planId:plan.id,
        planTitle:plan.title,
        planValidFrom:todayFormatted,
        planValidTo:planValidToFormatted
      }
      const result =  await axios.post(`${BASE_URL}/api/engager/update/${user?.userInfo?.id}`, data);

      if (result) {
        console.log("engagement posting data", result)
        // navigate(`/employer/engagement-posting`);
        
        alert("Plan Added Sucessfully!!")
        
        try{
        const result =  await axios.post(`${BASE_URL}/api/engagement/create`, sessionStorage.getItem("EngamentDetails"));
 
        if (result) {
          console.log("engagement posting data", result)
          // navigate(`/employer/engagement-posting`);
          let Unq = result?.data?.result?.data?.engagementId
          alert("Engagement Posted Sucessfully")
          sessionStorage.removeItem("EngamentDetails")
          // setSnack({
          //   message: `Engagement Posted successfully with Id ${Unq}`,
          //   open: true,
          //   type: 'success',
          // });
          // handleReset();
        }
        else {

        }
      } catch (error) {
        console.log("erroor", error)
        alert("Error occurred during Engagement Posting!")
        sessionStorage.removeItem("EngamentDetails")

        // setSnack({
        //   message: 'Error occurred during Engagement Posting!',
        //   open: true,
        //   type: 'error',
        // });
        console.log("ERROR LOGIN: ", { ...error });

      }

        //   message: `Engagement Posted successfully with Id ${Unq}`,
        //   open: true,
        //   type: 'success',
        // });
        // handleReset();
      }
      else {

      }
    } catch (error) {
      console.log("erroor", error)
      alert("Error occurred during Subscription, Try after sometime.")
      // setSnack({
      //   message: 'Error occurred during Engagement Posting!',
      //   open: true,
      //   type: 'error',
      // });
      console.log("ERROR LOGIN: ", { ...error });

    }

      


      // const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
   
      // if (!res) {
          //     alert("Razorpay SDK failed to load. Are you online?");
          //     return;
      // }
   
      // var orderData = {
          //     amount: 1000*10,
          //     currency: "INR"
      // };
   
      // try {
          //     let response = await fetch("http://localhost:8080/api/order/", {
           
              //         method: 'POST',
              //         headers: {
                  //             'Content-Type': 'application/json'
              //         },
              //         body: JSON.stringify(orderData)
          //     });
   //     console.log("api/order",response)
   
      //     if (!response.ok) {
              //         throw new Error("Internal Server Error,Try After Sometime.");
          //     }
   
          //     const data = await response.json();
          //     console.log("Order Data:", data);
          //     const options = {
              //         key: process.env.RAZOR_PAY_KEY,
              //         currency: data.currency,
              //         amount: data.amount,
              //         order_id: data.order_id,
              //         name: "Gigleji",
              //         description: "BASIC PLAN",
      //         handler: function (response) {
                  //             alert("Transaction successful");
              //         },
              //         prefill: {
                  //             name: "Rajat",
                  //             email: "rajat@rajat.com",
                  //             phone_number: "9899999999",
              //         },
          //     };
   
          //     const paymentObject = new window.Razorpay(options);
          //     paymentObject.open();
      // } catch (error) {
          //     console.error("Error:", error.message);
          //     alert("Error fetching order. Please try again later.");
      // }
   }
  
  useEffect(() => {
    axios
      .get(`${BASE_URL}/api/plan/`)
      .then((response) => {
        console.log('response', response.data);
        var temp = response.data?.result?.data;
        setPlansData(temp);
      })
      .catch((error) => {
        console.error('error', error);
      });
  }, []);

  const RupeeIcon = styled(FaRupeeSign)({
    fontSize: '2em',
    marginRight: '5px', 
  });
  
  const HoverableCard = styled(Card)({
    cursor: 'pointer',
    transition: 'transform 0.3s',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  });
  const handleModeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div >
       <section style={{ backgroundColor:'#db1111',height:"auto",borderRadius:'310px 0px 0px 0px'}}>
   
      <h4 style={{textAlign:'center',color:"#eeee",paddingTop:'110px'}}>Subscription Plan & Pricing</h4>
     
      <div className="container pt-2  d-md-flex justify-content-center">
       
        {plansData.map((plan, index) => (
          <div key={index}>
            <HoverableCard className="m-2" style={{backgroundColor:'#eeee',padding:'15px',boxShadow:'4px 4px 4px 4px rgba(200,0,0,0.6)',borderRadius:"10px"}}>
            <CardHeader title={plan.title} style={{ color: 'black' ,textAlign:'center'}} />
              <CardContent style={{ color: '#7e7e80' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',color:'#bb0f0f'}}>
                  <RupeeIcon />
                  <p style={{ fontSize: '1.5em', fontWeight: 'bold' ,color:'#bb0f0f'}}>
                    {plan.price}
                  </p>
                  <p style={{color:'#8c8b8b'}}>/month</p>
                </div>
                <p>{plan.numberOfUsers ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} {plan.numberOfUsers} - Users</p>
                <p>{plan.numberOfSubUsers ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} {plan.numberOfSubUsers} - Sub-user</p>
                <p>{plan.numberOfJobPostings ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} {plan.numberOfJobPostings} - Job-Postings</p>
                <p>{plan.contactDetails ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} Contact Details</p>
                <p>{plan.accessToAssessors ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} Access to Assessors</p>
                <p>{plan.paidAdditionalJobPostingOption ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} Paid Additional Job Posting Option {plan.paidAdditionalJobPostingOption}</p>
                <p>{plan.automaticProfileMatchingAlert ? <MdCheck style={{ color: 'green' }} /> : <MdClose style={{ color: 'red' }} />} Automatic profile matching</p>
              </CardContent>
              <div className="text-center">
                <button
                  style={{
                    width: '100%',
                    color: '#eeee',
                    border: 'none',
                    borderRadius: '5px',
                    backgroundColor: 'black',
                    padding: '10px',
                    marginBottom: '10px',
                  }}
                   onClick = {showRazorpay(plan)}
                >
                  Try for free
                </button>
                {/* <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_NQlwRAi81kNctR" async> </script> </form> */}
              </div>
            
            </HoverableCard>
          </div>
          
        ))}
      </div>
      </section>

      
    </div>
  );
};

export default Payment;
