import { Link } from 'react-router-dom';
import logo1 from '../src/images/logo-bg.png';
import IndusDomSkillTable from './IndusDomSkillList';
import React, { useState,useEffect} from 'react';
import axios from 'axios';
import { FaBriefcase, FaTools, FaHandshake } from 'react-icons/fa';
import SkillCalculator from './SkillCalculator';
import { BASE_URL } from './Constant';
 
const Industries = () => {
  const[industryData,setIndustryData]=useState([]);
  const[domSkillData,setDomSkillData]=useState([]);
 
  useEffect(()=>{
    axios.get(`${BASE_URL}/api/industry/`)
    .then((response)=>{
      let temp = response?.data?.result?.data;
      // console.log("indresponse",temp);
      setIndustryData(temp)
    })
    .catch(error=>{
      console.error('Some Error occured during fetching the data',error)
    });
  },[])
 
  useEffect(()=>{
    axios.get(`${BASE_URL}/api/domain/`)
    .then((response)=>{
      let temp = response?.data?.result?.data;
      // console.log("domskresponse",temp);
      setDomSkillData(temp)
    })
    .catch(error=>{
      console.error('Some Error occured during fetching the data',error)
    });
  },[])
  return (
    <div>
   
 
 <div  style={{backgroundColor:'#db1111',borderRadius:'310px 0px 0px 0px',paddingTop:'140px'}}>
 <h2 class="heading" style={{fontWeight:'bold',color:'white'}}>Find Trending<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}> Skills</span></h2>
      <div className="d-md-flex mb-2">
       <div className="p-4" >
 
    <p style={{ color: '#222', textAlign: 'center', backgroundColor: 'whitesmoke',paddingBottom: '40px', paddingRight: '40px', paddingLeft: '40px' ,borderRadius:'6px'}}>
      <FaBriefcase size={45} color="red" className="pt-2 pb-1"/><br></br>  <h5 class='pb-1' style={{ textAlign: 'center', color: '#222' }}>The Rise of Skillful Freelancing </h5>
      In today's rapidly evolving professional landscape, it is becoming increasingly common for industries to recognize and embrace the power of skillful freelancers. With advancements in technology and the growth of online platforms, companies are now able to tap into a diverse pool of talented individuals who offer their expertise on a project-specific basis.Explore the dynamic world of freelancing where innovation meets expertise.</p>
  </div>
 
 
   <div className="p-4">
<p style={{ color: '#222', textAlign: 'center', backgroundColor: 'whitesmoke',paddingBottom: '40px', paddingLeft: '40px', paddingRight: '40px',borderRadius:'6px',boxShadow:'4px 4px 4px 4px rgba(0,0,0,0.3)' }}>
      <FaTools size={50} color="red" className="pt-2 pb-1" /><br></br><h5 class="pb-1" style={{ textAlign: 'center', color: '#222' }}>Diverse Opportunities For All Industries</h5>
      This trend has opened up opportunities across numerous sectors, allowing businesses to access a wide range of specialized skills without the need for long-term commitments. From creative services such as graphic design and content creation to technical fields like programming and data analysis, freelancers are being sought after by organizations seeking flexible solutions.Embrace a borderless marketplace where innovation converges with talent.
      </p>
    </div>
 
          <div className="p-4" >
         
            <p style={{ color: '#222', textAlign: 'center', backgroundColor: 'whitesmoke',paddingBottom: '40px', paddingRight: '40px', paddingLeft: '40px' ,borderRadius:'6px'}}>
            <FaHandshake size={50} color="red" /><br></br>  <h5 class='pb-1' style={{ textAlign: 'center', color: '#222' }}>Mutual Benefits for Freelancers and Employers</h5>
              Moreover, this approach benefits both parties involved - freelancers gain the ability to work on varied projects that match their skillsets while employers benefit from tapping into niche talent pools without having to bear fixed overhead costs associated with traditional employment models. As a result, this paradigm shift towards skillful freelancing is set to continue thriving in industries worldwide, driving innovation and collaboration across various domains.</p> </div>
        </div>
 
 
      <section className="container">
   
      <h2 class="heading pb-3 " style={{fontWeight:'bold',color:'white'}}>Industries.Domain.<span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}>Skills</span></h2>
 <IndusDomSkillTable data={industryData.map(industry => {
    const domainDataAccordingToIndustry = domSkillData?.filter((domain) => domain.Industry === industry.Industry);
    return {
      title: industry.Industry,
      subTitles: domainDataAccordingToIndustry.map(item => item.Domain),
      chipDatas: domainDataAccordingToIndustry.map(item => item.skillSet),
    };
  })} />
</section>
<br></br>
    </div>
    <SkillCalculator/>
    </div>
 
  );
};
 
export default Industries;