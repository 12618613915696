import React from 'react';
 
const Refund = () => {
  return (
    <div class="container">
      <header >
    <a href="javascript:history.back()" style={{position:"fixed", top:"10px", right:"10px" , backgroundColor:"white", border:"1px solid #ccc" , color:"#333" ,padding:"10px", textDecoration:"none"}}>Back</a>
        <h1>Return and Refund Policy of gigleji</h1>
        <p>Last updated: December 16, 2023</p>
      </header>
 
      <section>
        <p>Thank you for choosing gigleji.</p>
        <p> If, for any reason, You are not completely satisfied with a subscription,
          We invite You to review our policy on refunds and cancellations.
        </p>
 
        <h2>Interpretation and Definitions</h2>
 
        <h3>Interpretation</h3>
        <p>
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <h3>Definition</h3>

<p>For the purposes of this Return and Refund Policy :</p>
 <p>
Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to GIGLEJI TEKNET PRIVATE LIMITED,, 180, Ground-floor, 8th Cross Rd, CQAL Layout, Sahakar Nagar, Bengaluru, Karnataka 560092.</p>
 <p>
Subscription refers to the recurring service offered on the Website.
 
Website refers to gigleji, accessible from www.gigleji.com
 
You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
 </p>
 <strong>
Your Subscription Cancellation Rights :</strong>
<p> (i) 
You are entitled to cancel Your Subscription within 7 days without giving any reason for doing so.</p>
 
<p> (ii) The deadline for cancelling a Subscription is 7 days from the date on which You initiated the subscription.</p>
 
<p> (iii) In order to exercise Your right of cancellation, You must inform Us of your decision by means of a clear statement. You can inform us of your decision by:
 
By email: support@gigleji.com
We will reimburse You no later than 14 days from the day on which You cancel the Subscription.</p>
<p>
(iv) We will use the same means of payment as You used for the Subscription, and You will not incur any fees for such reimbursement.
</p>
<p>(iv) Conditions for Cancellations
To be eligible for a subscription cancellation, please make sure that:
 
The Subscription was initiated in the last 7 days.
Refunds are not applicable for subscriptions that have been active for more than 7 days.
 
We reserve the right to refuse cancellations that do not meet the above conditions in our sole discretion.</p>
        {/* ... Other HTML content ... */}
 
 <strong>shipping and delivery policy :</strong>
 <p>we are not providing shipping and delivery</p>

 <strong>Plan's and Pricing:</strong>
 {/* <p> (i) BASIC  </p> */}
 <table>
        <tr>
            <th>Plan Name</th>
            <th>Pricing</th>
        </tr>
        <tr>
            <td> (i) Basic </td>
            <td>  ₹9,000</td>
        </tr>
        <tr>
            <td> (ii) Classic</td>
            <td> ₹19,000 </td>
        </tr>
        <tr>
            <td> (iii) Premium</td>
            <td>₹29,000</td>
        </tr>
    </table>
    <br></br>
        <h2>Contact Us</h2>
 
        <p>If you have any questions about our Returns and Refunds Policy, please contact us:</p>

        <ul>
          <li>By email: <a href="mailto:support@gigleji.com">support@gigleji.com</a></li>
        </ul>
      </section>
    </div>
  );
};
 
export default Refund;