import React, { useEffect, useState } from 'react';
import talent1 from '../src/images/forTalents1.png';
import talent2 from '../src/images/forTalents2.png';
import talent3 from '../src/images/forTalents3.png';
import talent4 from '../src/images/forTalents4.png';



function ForEngager(){

    return(
        <section>
        <div>
        <div  style={{backgroundColor:'#db1111',borderRadius:'310px 0px 0px 0px'}}>
        <h2 class="heading" style={{fontWeight:'bold',color:'white',padding:'140px'}}>4 Steps To <span class="text-dark" style={{fontfamily:'cursive',fontStyle:'italic'}}>Success</span></h2> 
    {/* <p style={{color:'#eeee',textAlign:'center',padding:'10px',paddingTop:'0px'}}>
            Joining Gigleji as a talented individual opens doors to a world of opportunities. Create your profile, showcase your skills, and connect with Engagers looking for your expertise.Gigleji is your platform to shine in the gig economy and achieve success on your terms!
        </p> */}
        {/* <div className="d-md-flex  m-5" style={{justifyContent:'space-evenly',paddingBottom:'100px'}}  >
       <div> 
       <h3 className=" p-5  text-danger text-center animate__animated animate__zoomIn animate__delay-1s" style={{backgroundColor:'black',borderRadius:'10px',boxShadow:'4px 4px 4px 4px'}} ><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>R</span>egister</h3>
       </div>
       <div>
        <h3 className="p-5  text-white text-center animate__animated animate__zoomIn animate__delay-1s" style={{backgroundColor:"#a20505",borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>U</span>pload profile</h3>
       </div>
       <div> 
        <h3 className='p-5 text-danger text-center animate__animated animate__zoomIn animate__delay-1s' style={{backgroundColor:'black',borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>G</span>et assesed</h3>
       </div>
       <div> 
        <h3 className=' p-5  text-white text-center animate__animated animate__zoomIn animate__delay-1s' style={{backgroundColor:'#a20505',borderRadius:'10px'}}><span style={{fontFamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>C</span>hoose talents</h3>
       </div>
      </div> */}
           </div>
           
          <div className="container">
              <div class="d-md-flex mt-2">
              <div >
            <h3 class="text-black" style={{paddingTop:'20px'}}>Register <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Now</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Seamless registration at Gigleji allows you to log into the platform anytime.</h6>
            <p style={{color:'#222',textAlign:'justify'}}>
            Unlocking the power of Gigleji begins with a simple registration process.
    Head to our portal, fill the required information, and you're officially
    part of the Gigleji community. Join us to explore exciting opportunities,
    connect with talented individuals, and make your mark in the gig economy.
    Don't miss out on the chance to be a part of something great!
                </p>        
    <button type="button" className="btn btn-danger btn-md">
      <a className="nav-link" href="https://app.gigleji.com/register">
        Register Now
      </a>
    </button>
           </div>
           <div>
       <img src={talent1} width='100%' height="auto"></img>
           </div>
           </div>
           <div class="d-md-flex mt-2">
           <div>
       <img src={talent2} width='100%' height="auto"></img>
           </div>
              <div className="p-2">
            <h3 class="text-black" style={{paddingTop:'20px'}}>Upload <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Profile</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Build your profile to attract your dream gigs</h6>
            <p style={{color:'#222',textAlign:'justify'}}>
            You will have to upload your resume and fill in the required details, completing your profile.
                  This will help you build a category for yourself and have relevant opportunities presented to you.
                  From work experience to education details, everything must be put in correctly as it gives the employer
                  transparency while hiring you.
                </p>        
           </div>
           </div>
           <div class="d-md-flex mt-2">
              <div >
            <h3 class="text-black" style={{paddingTop:'20px'}}>Get <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}}>Assessed</span></h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>We ensure quality in every step</h6>
            <p style={{color:'#222',textAlign:'justify'}}>  Gigleji has a number of industry experts who are present to assess all the talents who sign up.
                  This gives the employers an assurance and you the credibility of being a trusted gig talent.At Gigleji, we believe that quality is the cornerstone of success in the gig economy. By undergoing our thorough assessment, you not only enhance your chances of landing exciting gigs but also contribute to building a community of high-caliber professionals.
                </p>        
           </div>
              <div>
       <img src={talent3} width='100%' height="auto"></img>
           </div>
           </div>
            
           <div class="d-md-flex mt-2">
           <div>
       <img src={talent4} width='100%' height="auto"></img>
           </div>
              <div className="p-2">
            <h3 class="text-black" style={{paddingTop:'20px'}}>Choose your <span style={{fontfamily:'cursive',fontStyle:'italic',fontWeight:'normal'}} >Gig</span> </h3>
            <h6 className="pt-1" style={{fontWeight:'bold'}}>Have the freedom to choose who you work with</h6>
            <p style={{color:'#222',textAlign:'justify'}}>
            Gigleji gives you the liberty to work on projects that align with your skill set and interests.
                  You can understand and choose from an array of options. The idea of work satisfaction is what you can
                  get here as you choose your employer, your time and your kind of work. Sounds like a good plan, right?
                </p>        
                <button type="button" className="btn btn-danger btn-md">
                  <a className="nav-link" href="https://app.gigleji.com/register">
                Build your profile
                  </a>
                </button>
           </div>
           </div>   
       </div>
        </div> 
    </section>
    );
}
export default ForEngager;