import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './home';
import About from './AboutUs';
import ForEngager from './ForEngager';
import ForTalents from './ForTalents';
import Engagerquestions from './Engagerquestions';
import EngagerTermsAndConditions from './EngagerTermsandConditions';
import EngagerPrivacyPolicy from './EngagerPrivacyPolicy';
import Refund from './Refund';
import Giglerquestions from './Giglerquestions';
import Contact from './contactUs';
import './App.css';
import Payment from './Payment';
import Industries from './industries';
import './IndusDomSkill.css';
import Navbar from './navbar';
import Footer from './footer';
const App = () => {
 
  return (
 
      <div className={{backgroundAttachment:'fixed'}}>
        <Navbar/>
        <Routes>
        <Route path="" element={<Home />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="/aboutus" element={<About />}/>
        <Route path="/industries" element={<Industries/>}/>
        <Route path="/forEngager" element={<ForEngager />}/>
        <Route path="/forTalents" element={<ForTalents />}/>
        <Route path="contact" element={<Contact/>}></Route>
        <Route path="/engagementPosting" element={<Engagerquestions />}/>
        <Route path="/EngagementSelection" element={<Giglerquestions />}/>
        <Route path="/termsandconditions" element={<EngagerTermsAndConditions />}/>
        <Route path="/Privacypolicy" element={<EngagerPrivacyPolicy />}/>
        <Route path="/Refundpolicy" element={<Refund />}/>
        <Route path="/paymentmodule" element={<Payment />}/>
        </Routes>
        <Footer/>
      </div>
     
  );
}

export default App;
