import React from "react";
import { useState } from "react";

import {
  TextField,
  Autocomplete,
  InputAdornment,
  Box,
  Button,
  Typography,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";

export default function SkillCalculator() {
  const theme = createTheme({
    palette: {
      giglejiColor: {
        main: "#e63939",
      },
    },
  });
  const workHours = ["1", "2", "3", "4", "5", "6", "7", "8"];
  const [earnings, setEarnings] = useState("");
  const [working, setWorking] = useState("");
  const [totalEarning, setTotalEarning] = useState("");
  const formik = useFormik({
    initialValues: {
      ctc: "",
      hours: "",
    },

    onSubmit: (values) => {
      let temp1 = parseInt(values.ctc.replace(/,/g, ""), 10);
      let temp2 = values.hours;
      let temp3 = temp1 / 12;
      const perHour = temp3 / 20 / 8;
      const temp4 = perHour * temp2 * 30;
      setTotalEarning(temp4);
      const persentageEarning = (100 / temp3) * temp4;
      console.log("persentageEarning", persentageEarning);
      setEarnings(persentageEarning);
      const persentageWorking = (100 / 8) * temp2;
      console.log("persentageWorking", persentageWorking);
      setWorking(persentageWorking);
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
    
        <form className=" text-center" onSubmit={formik.handleSubmit} style={{backgroundColor:"#fceeee",padding:'80px'}} >
        <h2 class="heading " style={{fontWeight:'bold',color:'#222'}}>Unleash Your<span class="text-danger" style={{fontfamily:'cursive',fontStyle:'italic'}}> Potential</span> </h2>
  <p>Calculate Your Earnings Based on Your Unique Talents</p>
          <Box style={{ display: "flex", alignItems: "center" ,justifyContent:'center'}}>
            <TextField
              label="CTC"
              id="ctc"
              name="ctc"
              sx={{ m: 2 }}
              color="giglejiColor"
              
              onChange={formik.handleChange}
              value={formik.values.ctc}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₹</InputAdornment>
                ),
              }}
            />

            {formik.values.ctc && (
              <Box>
                {/* <Typography sx={{ color: "#000000" }}>
                  For How Much{" "}
                  <span style={{ color: theme.palette.giglejiColor.main }}>
                    Hours
                  </span>{" "}
                  would you like you work.
                </Typography> */}
                <Autocomplete
                  id="hours"
                  name="hours"
                  options={workHours}
                
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Hours"
                      color="giglejiColor"
                      onChange={formik.handleChange}
                      value={formik.values.hours}
                      
                    />
                  )}
                  onChange={(event, hour) => {
                    formik.setFieldValue("hours", hour);
                  }}
                />
              </Box>
            )}
          </Box>
          <Button
            variant="contained"
            color="giglejiColor"
            type="submit"
            disabled={!formik.values.ctc || !formik.values.hours}
            sx={{
              m:2,
            
              color: "#ffffff",
              "&:disabled": {
                backgroundColor: "#e63939",
                opacity: 0.6,
                color: "#ffffff",
              },
            }}
          >
            SUBMIT
          </Button>

          {earnings && working && (
            <Box sx={{ ml: 1 }}>
              <Typography variant="h6" sx={{ color: "#222" }}>
                <span style={{fontWeight:'bold',fontSize:'26px',fontStyle:'italic'}}>Wow! </span>your Earnings will be{" "}
                <span style={{ color: theme.palette.giglejiColor.main }}>
                  {totalEarning}
                </span>{" "}
                which is{" "}
                <span style={{ color: theme.palette.giglejiColor.main }}>
                  {earnings}%
                </span>{" "}
                of your total income by working only for{" "}
                <span style={{ color: theme.palette.giglejiColor.main }}>
                  {working}%
                </span>{" "}
                of your total time.
              </Typography>
            </Box>
          )}
        </form>
      </ThemeProvider>
    </>
  );
}
